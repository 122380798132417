import React, { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FormField from "./components/FormField";
import useQuestionService from "service-hooks/useQuestionService";
import { update } from "lodash";

function Questions() {
    const questionHookService = useQuestionService();
    const [questions, setQuestions] = useState([]);
    const [pageRefresh, setPageRefresh] = useState(false)
    const [addBtnActive, setAddBtnActive] = useState(false)

    useEffect(() => {
        questionHookService.questions().then((result) => {
            if (result) {
                setQuestions(result);
            } else {
                console.error("Invalid result format:", result);
            }
        });
    }, [pageRefresh]);

    const handleChange = (index, question) => {
        const updatedQuestions = [...questions]
        updatedQuestions[index].question = question
        setQuestions(updatedQuestions)

    };

    const handleAddQuestion = () => {
        if (questions.length === 0 || (questions.length > 0 && questions[questions.length - 1]?.question?.trim() !== "")) {
            const newQuestion = {
                question: "",
            };
            setQuestions((prevQuestions) => [...prevQuestions, newQuestion]);
            setAddBtnActive(true)
        } else {
            toast.error("Please fill the previous question before adding a new one.");
        }
    };


    const handleRemoveQuestion = async (index) => {
        try {
            setAddBtnActive(false)
            await questionHookService.destroy(questions[index].id);
            setQuestions((prevQuestions) => {
                const updatedQuestions = prevQuestions.filter((q) => q.id !== questions[index].id);
                return updatedQuestions;
            });
            toast.success("Question deleted successfully");
        } catch (error) {
            toast.error("Failed to delete the question. Please try again.");
        }
    };

    const checkLastFieldConditions = (index) => {
        const isLastFieldEdited = questions.length > 0 && index === questions.length - 1;
        const isLastFieldEmpty = questions.length > 0 && questions[questions.length - 1].question.trim() === "";
        if (isLastFieldEdited && isLastFieldEmpty) {
            setAddBtnActive(true);
        } else if (!isLastFieldEdited && isLastFieldEmpty) {
            setAddBtnActive(true);
        }
        else {
            setAddBtnActive(false);
        }
    };

    const handleBlur = async (index, value) => {
        try {
            if (questions[index].id) {
                if (value.trim() !== "") {
                    await questionHookService.update({ id: questions[index].id, questionData: value });
                    toast.success("Question updated successfully");
                }
            } else {
                if (value.trim() !== "") {
                    const response = await questionHookService.create(value.trim());
                    if (response && response.data) {
                        const updatedQuestions = await questionHookService.questions();
                        if (updatedQuestions) {
                            setQuestions(updatedQuestions);
                            toast.success("Question created successfully");
                        } else {
                            toast.error("Failed to fetch updated questions. Please try again.");
                        }
                    } else {
                        toast.error("Failed to create a new question. Please try again.");
                    }
                }
            }
            checkLastFieldConditions(index)
        } catch (error) {
            console.log(error)
            toast.error("Failed to update/create the question. Please try again.");
        }
    };
    return (
        <Grid item xs={6}>
            <Card sx={{ overflow: "hidden" }}>
                <ArgonBox px={2} py={3}>
                    <ArgonTypography variant="h5">Feedback Questions</ArgonTypography>
                </ArgonBox>
                <ArgonBox component="form" pb={3} px={2}>
                    <Grid container spacing={3}>
                        {questions?.map((question, index) => (
                            <Grid item xs={12} key={index}>
                                <FormField
                                    label={`Question ${index + 1}`}
                                    placeholder={`Write question ${index + 1}`}
                                    name={`question-`}
                                    value={question?.question}
                                    onChange={(e) => handleChange(index, e.target.value)}
                                    required={true}
                                    type={"string"}
                                    onBlur={(e) => {
                                        handleBlur(index, question?.question);
                                    }}
                                    deleteAction={() => handleRemoveQuestion(index)}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </ArgonBox>
                <ArgonBox mb={2} sx={{ display: "flex" }}>
                    <ArgonButton
                        onClick={handleAddQuestion}
                        sx={{ boxShadow: "none", color: "#0A9CC4", fontSize: "14px" }}
                        disabled={addBtnActive}
                    >
                        <AddIcon sx={{ marginRight: "10px", color: "#0A9CC4", width: "24px", height: "24px" }} />
                        Add More Questions
                    </ArgonButton>
                </ArgonBox>
            </Card>
        </Grid>
    );
}

export default Questions;
