import { useState, useEffect, useMemo } from "react";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTransactionHistoryService from '../../../../../service-hooks/clinic/useTransactionHistoryService'
import DownloadIcon from "assets/images/icons/clinic/download.svg"
import ActionMenu from "blikol-layouts/components/ActionMenu";
import React from 'react'
import useBaseService from "../../../../../service-hooks/useBaseService";
import useExportCSVService from "../../../../../service-hooks/useExportCSVService";
import Spinner from "blikol-layouts/components/Spinner";
import useDownloadReceipt from "service-hooks/useDownloadReceipt";


function History() {
    const transactionServiceHook = useTransactionHistoryService()
    const exportCSVServiceHook = useExportCSVService()
    const { user, token } = useSelector((state) => state.auth)
    const [clinicId, setClinicId] = useState(user?.id)
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [data, setData] = useState();
    const [search, setSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [totalEntries, setTotalEntries] = useState();
    const [totalPages, setTotalPages] = useState();
    const [dateRange, setDateRange] = useState("")
    const [tabValueForService, setTabValueForService] = useState("credits_history")
    const [loading, setLoading] = useState(true)
    const downloadReceiptHook = useDownloadReceipt()

    useEffect(() => {
        transactionServiceHook
            .history(pageNo, entriesPerPage, search, dateRange, clinicId)
            .then((result) => {
                if (result && result?.meta && result?.payments) {
                    setData(result);
                    setLoading(false)
                    setTotalEntries(result?.meta?.total_count);
                    setTotalPages(result?.meta?.total_pages);
                } else {
                    toast.error("Data is undefined or incomplete.");
                }
            })
            .catch((error) => {
                // Handle the error (e.g., display an error message)
                toast.error("Error fetching user data and attributes:", error);
            });

    }, [
        entriesPerPage,
        pageNo,
        search,
        dateRange
    ]
    );

    const handleDateRangeChange = (newDateRange) => {
        setDateRange(newDateRange);
    };

    const handleReceiptDownload = (id) => {
        downloadReceiptHook
            .downloadReceipt(id)
            .then((result) => {
                setLoading(true)
                if (result) {
                    const downloadLink = document.createElement('a');
                    downloadLink.href = result.receipt_url;
                    document.body.appendChild(downloadLink);
                    downloadLink.click();
                    document.body.removeChild(downloadLink);
                    setTimeout(() => {
                        setLoading(false)
                        toast.success('Receipt downloaded successfully');
                    }, 3000);
                } else {
                    toast.error('Error downloading receipt');
                }
            })
            .catch((error) => {
                toast.error('Error fetching user data and attributes:', error);
            });

    };

    const handleExportCSV = () => {
        const pathname = `/clinic_apis/clinics/${clinicId}/${tabValueForService}_export?date_range=${dateRange}`
        const filename = `clinic_${clinicId}_transaction_history`
        exportCSVServiceHook.exportCSV(pathname, filename)

    };

    const customIcons = [
        {
            action: handleReceiptDownload,
            icon: <img src={DownloadIcon} alt="Download" />,
        },
    ];

    function amountColumn(value) {
        const parsedValue = parseFloat(value);

        if (!isNaN(parsedValue)) {
            const formattedValue = parsedValue.toFixed(2);
            return `€${formattedValue}`;
        } else {
            return value;
        }
    }

    const paymentsHistoryRowsData = data?.payments?.map((payment, index) => ({
        ...payment,
        amount: amountColumn(payment?.amount),
        action: (
            <ActionMenu
                row={payment}
                customIcons={customIcons}
                key={index + 1}
            />
        ),
    })) ?? [];

    const tableDataHistory = {
        columns: [
            { Header: "date", accessor: "date" },
            { Header: "order number", accessor: "registration_id" },
            { Header: "package", accessor: "package_name" },
            { Header: "amount", accessor: "amount" },
            { Header: "credits purchased", accessor: "credits" },
            { Header: "free credits", accessor: "free_credits" },
            { Header: "action", accessor: "action" }
        ],
        rows: paymentsHistoryRowsData,
    };

    return (
        <>
            {loading && <Spinner />}
            {!loading && (<DataTable
                canExportCSV={{
                    name: "Export as CSV",
                    action: handleExportCSV
                }}
                canSearch
                canFilter
                dateFilter
                onDateRangeChange={handleDateRangeChange}
                dataTableName={"Transaction History "}
                entriesPerPage={entriesPerPage}
                setSearch={setSearch}
                search={search}
                totalPages={totalPages}
                setEntriesPerPage={setEntriesPerPage}
                pageNo={pageNo}
                setPageNo={setPageNo}
                totalEntries={totalEntries}
                table={tableDataHistory}
            />)}
        </>
    )
}

export default History