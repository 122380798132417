import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


const API_BASE_URL = process.env.REACT_APP_BACKEND_URL;
const useExportCSVService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();

    function formatDateTime(date) {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');

        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        const seconds = String(date.getSeconds()).padStart(2, '0');

        return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    }

    const exportCSV = async (pathname, filename) => {
        try {
            const response = await axiosRequest.get(`${pathname}`, {
                headers: {
                    'Accept': 'text/csv',
                },
                responseType: 'blob',
            }
            );
            const blob = new Blob([response.data], { type: 'text/csv' });
            // Generate a dynamic file name with the current date and time
            const currentDate = formatDateTime(new Date())

            const fileName = `${filename}_${currentDate}.csv`;
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            toast.success("File exported successfully")
        } catch (error) {
            toast.error('Error exporting file.');
            return false;
        }
    };

    return {
        exportCSV
    };
};
export default useExportCSVService;
