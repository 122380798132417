import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import PatientForm from "blikol-layouts/clinic/patients/components/Form/patientForm";
import usePatientsService from "service-hooks/clinic/usePatientsService";
import Spinner from "../../../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";

function EditPatient() {
    const navigate = useNavigate();
    const patientHookService = usePatientsService();
    const [loading, setLoading] = useState(true);
    const [patientData, setPatientData] = useState();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [patientId, setPatientId] = useState(useParams().id);

    useEffect(() => {
        patientHookService
            .patient(patientId)
            .then((result) => {
                if (result) {
                    const patient = result.data;
                    setLoading(false);
                    setPatientId(patient?.id);
                    setPatientData(
                        result.data
                    );
                } else {
                    toast.error("No Patient with this id");
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    }, []);

    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Patients / Edit Patient"} />
            {loading && <Spinner />}
            {!loading && (
                <PatientForm
                    patientId={patientId}
                    patientData={patientData}
                    heading={"Edit Patient"}
                    buttonText={"Update"}
                />
            )}
        </DashboardLayout>
    );
}

export default EditPatient;
