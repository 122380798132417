/* eslint-disable react/prop-types */

/**
=========================================================
* Argon Dashboard 2 PRO MUI - v3.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-mui
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useState } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonSelect from "components/ArgonSelect";
import ArgonTagInput from "components/ArgonTagInput";
import ArgonButton from "components/ArgonButton";
import useSettingService from "service-hooks/useSettingService";
// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "blikol-layouts/components/Spinner";
//validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

function Diagnostic() {
  const settingHookService = useSettingService();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    settingHookService.settings().then((result) => {
      if (result) {
        const diagnosticData = result.data.diagnostic;
        setFormData({
          frequency_difference: diagnosticData.frequency_difference,
          notch_angle: diagnosticData.notch_angle,
          frequency_duration: diagnosticData.frequency_duration,
          q_value_setting: diagnosticData.q_value_setting,
        });
        setLoading(false)
      } else {
        console.log("Unable to fetch settings");
      }
    });
  }, []);
  const validationSchema = Yup.object({
    frequency_difference: Yup.number()
      .positive()
      .integer()
      .required('Frequency Difference is required'),
    notch_angle: Yup.number()
      .positive("Q value should be a positive integer")
      .integer("Q value should be a positive integer")
      .required('Q value is required'),
    frequency_duration: Yup.number()
      .positive()
      .integer()
      .required('Frequency Duration is required'),
    q_value_setting: Yup.number()
      .min(-100, 'Q Value Setting cannot be less than -100')
      .max(100, 'Q Value Setting cannot exceed 100')
      .required('Q Value Setting is required')
  });

  const handleSubmit = (values) => {
    const parsedValues = {
      ...values,
      frequency_difference: parseFloat(values.frequency_difference),
      notch_angle: parseInt(values.notch_angle),
      frequency_duration: parseFloat(values.frequency_duration),
      q_value_setting: parseFloat(values.q_value_setting)
    };

    settingHookService
      .update({ diagnostic: parsedValues })
      .then(() => {
        toast.success(`Diagnostic settings updated successfully.`);
      })
      .catch((error) => {
        toast.error(error);
      });
  };


  return (
    <>
      {loading && <Spinner />}
      {!loading && <Grid item xs={10}>
        <Card sx={{ overflow: "hidden" }}>
          <ArgonBox p={3}>
            <ArgonTypography variant="h5">Diagnostic & App</ArgonTypography>
          </ArgonBox>
        </Card>

        <Formik
          initialValues={formData}
          validationSchema={validationSchema}
          onSubmit={(values) => handleSubmit(values)}
        >
          {(props) => (
            < Form >
              <ArgonBox sx={{ display: "flex", flexDirection: "column", gap: "20px" }} mt={2}>
                <Card sx={{ overflow: "hidden" }}>
                  <ArgonBox px={3} pb={2}>
                    <ArgonBox py={3}>
                      <ArgonTypography variant="h6">Diagnostic Configuration</ArgonTypography>
                    </ArgonBox>
                    <Grid item xs={12}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          label="allowed deviation %"
                          placeholder="e.g 10"
                          name="frequency_difference"
                          value={props.values.frequency_difference}
                          onChange={props.handleChange}
                          required={true}
                          error={props.errors.frequency_difference && props.touched?.frequency_difference}
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          label="tone duration (secs)"
                          placeholder="e.g 10"
                          name="frequency_duration"
                          value={props.values.frequency_duration}
                          onChange={props.handleChange}
                          required={true}
                          error={props.errors.frequency_duration && props.touched?.frequency_duration}
                        />
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </Card>

                <Grid item xs={12}>
                  <Card sx={{ overflow: "hidden" }}>
                    <ArgonBox px={3} pb={2}>
                      <ArgonBox py={3}>
                        <ArgonTypography variant="h6">App Notch</ArgonTypography>
                      </ArgonBox>
                      <Grid item xs={12} sm={6}>
                        <FormField
                          name="q_value_setting"
                          label="gain (-XdB)"
                          value={props.values.q_value_setting}
                          placeholder="e.g 10"
                          onChange={props.handleChange}
                          required={true}
                          error={props.errors.q_value_setting && props.touched?.q_value_setting}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <Grid item xs={12} sm={6}>
                          {/* client changed the name of the label of notch angle to q value and the name of q value to depth value */}
                          <FormField
                            label="Q value"
                            value={props?.values?.notch_angle}
                            name="notch_angle"
                            placeholder="e.g 10"
                            onChange={props?.handleChange}
                            required={true}
                            error={props.errors.notch_angle && props.touched?.notch_angle}
                          />
                        </Grid>
                      </Grid>
                    </ArgonBox>
                  </Card>

                </Grid>
                <ArgonBox mt={3}>
                  <ArgonButton
                    type="submit"
                    color="info"
                  >
                    Update
                  </ArgonButton>
                </ArgonBox>
              </ArgonBox>
            </Form>
          )}
        </Formik>
      </Grid >}
    </>

  );

}

export default Diagnostic;
