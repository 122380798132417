import React from 'react'
//components 
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import Card from "@mui/material/Card";
import Header from "./components/Header"
import { useState } from "react";
import History from "./components/History"
import CreditBonus from "./components/CreditBonus"
import CreditsUsed from './components/CreditsUsed';
function TransactionHistory() {
    const [tabValue, setTabValue] = useState("history")

    function formatString(inputString) {
        const words = inputString.split(' ');
        const formattedWords = words.map((word, index) => {
            if (index === 0) {
                return word.toLowerCase();
            } else {
                return word.charAt(0).toLowerCase() + word.slice(1);
            }
        });
        const formattedString = formattedWords.join('_');
        return formattedString;
    }

    const handleTabChange = (selectedTab) => {
        const tabValue = formatString(selectedTab)
        setTabValue(tabValue);
    };

    return (
        <DashboardLayout>
            <DashboardNavbar customRoutePath={"Transaction History"} />
            <Header onTabChange={handleTabChange} />
            <Card p={1}>
                <ArgonBox>
                    {tabValue === "history" && (
                        <History />
                    )}
                    {tabValue === "credit_bonus" && (
                        <CreditBonus />
                    )}
                    {tabValue === "credits_used" && (
                        <CreditsUsed />
                    )}
                </ArgonBox>
            </Card>
        </DashboardLayout >
    )
}

export default TransactionHistory