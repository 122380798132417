import React from 'react'
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PersonIcon from "assets/images/icons/patients/person-icon.svg"
import usePatientsService from "service-hooks/usePatientsService";
import Spinner from "../../../../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ArgonButton from "components/ArgonButton";
import { formatDate } from 'parsers/formatParser';

function PersonalInfo() {
    const navigate = useNavigate();
    const patientHookService = usePatientsService();
    const [patientData, setPatientData] = useState();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [patientId, setPatientId] = useState(useParams().id);

    useEffect(() => {
        patientHookService
            .patient(patientId)
            .then((result) => {
                if (result) {
                    const patient = result.data;
                    setPatientId(patient?.id);
                    setPatientData(
                        result.data
                    );
                } else {
                    toast.error("No Patient with this id");
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    }, []);

    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        textTransform: "capitalize",

    }

    const fieldValueEmpty = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        display: "flex",
        justifyContent: "center"

    }

    const fieldEmailValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    return (
        <Card
            sx={{
                borderRadius: '20px',
                background: '#FFF',
                boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.05)',
            }}
        >
            <ArgonBox
                sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center"
                }}
            >
                <ArgonBox sx={{ padding: "30px" }}>
                    <img src={PersonIcon} alt="PersonIcon" />
                </ArgonBox>
                <ArgonBox sx={{ display: "flex", flexDirection: "column" }}>
                    <ArgonTypography
                        sx={{
                            color: "#000",
                            fontSize: "24px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "normal",
                        }}
                        mb={0.5}
                    >
                        {patientData?.full_name}
                    </ArgonTypography>
                    <ArgonTypography
                        sx={{
                            color: "#000",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            lineHeight: "normal",
                            textTransform: "capitalize"
                        }}
                    >
                        {patientData?.gender}

                    </ArgonTypography>

                </ArgonBox>

            </ArgonBox>
            <Grid item xs={12} sm={12} p={3}>
                <ArgonTypography
                    sx={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '137%',
                        letterSpacing: '-0.8px',
                    }}
                    mb={2}
                >
                    Clinic Info
                </ArgonTypography>

                <Card
                    sx={{
                        padding: '30px',
                        gap: '10px',
                        borderRadius: '20px',
                        background: '#F4F2F2',
                    }}
                >
                    <ArgonBox
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Clinic Name
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.clinic?.name}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Email
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldEmailValueStyle}
                                my={1}
                            >
                                {patientData?.clinic?.email}
                            </ArgonTypography>
                        </ArgonBox>

                    </ArgonBox>

                </Card>
                <ArgonTypography
                    sx={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '137%',
                        letterSpacing: '-0.8px',
                    }}
                    my={2}
                >
                    Tinnitus Info
                </ArgonTypography>

                <Card
                    sx={{
                        padding: '30px',
                        gap: '10px',
                        borderRadius: '20px',
                        background: '#F4F2F2',
                    }}
                >
                    <ArgonBox
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Tinnitus Type
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.tinnitus_type}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Ears
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.ears}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Start date of tinnitus
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {formatDate(patientData?.tinnitus_start_date)}
                            </ArgonTypography>
                        </ArgonBox>

                    </ArgonBox>

                </Card>
                <ArgonTypography
                    sx={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '137%',
                        letterSpacing: '-0.8px',
                    }}
                    my={2}
                >
                    Basic Info
                </ArgonTypography>

                <Card
                    sx={{
                        padding: '30px',
                        gap: '10px',
                        borderRadius: '20px',
                        background: '#F4F2F2',
                    }}
                >
                    <ArgonBox
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '16px',
                        }}
                    >
                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Phone
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.phone_number}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Email
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldEmailValueStyle}
                                my={1}
                            >
                                {patientData?.email}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Date of Birth
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {formatDate(patientData?.dob)}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Address
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.address1}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                City
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.city}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Postal Code
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.postal_code}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                State/Province
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.state}
                            </ArgonTypography>
                        </ArgonBox>

                        <ArgonBox>
                            <ArgonTypography
                                sx={fieldNameStyle}
                            >
                                Country
                            </ArgonTypography>
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.country}
                            </ArgonTypography>
                        </ArgonBox>

                    </ArgonBox>

                </Card>

                <ArgonTypography
                    sx={{
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '137%',
                        letterSpacing: '-0.8px',
                    }}
                    my={2}
                >
                    Note
                </ArgonTypography>

                <Card
                    sx={{
                        padding: '30px',
                        gap: '10px',
                        borderRadius: '20px',
                        background: '#F4F2F2',
                    }}
                >
                    <ArgonBox>
                        <ArgonTypography
                            sx={fieldNameStyle}
                        >
                            Note
                        </ArgonTypography>
                        {patientData?.note ? (
                            <ArgonTypography
                                sx={fieldValueStyle}
                                my={1}
                            >
                                {patientData?.note}
                            </ArgonTypography>) :
                            (
                                <ArgonTypography
                                    sx={fieldValueEmpty}
                                    my={1}
                                >
                                    No note available!
                                </ArgonTypography>
                            )

                        }
                    </ArgonBox>





                </Card>

            </Grid >

        </Card >
    )
}

export default PersonalInfo