const { Option } = components;
import PropTypes from "prop-types";

import ArgonSelect from "components/ArgonSelect";
import { components } from "react-select";
import masterCardIcon from "../../../../../../assets/images/icons/blikol/mastercard-icon.png";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

const CustomOptions = (props) => {
  const selectedDotStyles = {
    position: "absolute",
    right: "2%",
    width: "15px",
    height: "15px",
    backgroundColor: `${props.isSelected ? "#0A9CC4" : "white"}`,
    border: "1px solid #AAB5BF",
    borderRadius: "50%",
  };
  return (
    <Option {...props}>
      <ArgonBox display="flex" alignItems="center" gap="0.5rem">
        <ArgonBox component="img" src={props?.data?.icon || masterCardIcon} width="3rem" />

        <ArgonTypography fontWeight="light" fontSize="medium">
          {props?.data?.label}
        </ArgonTypography>
        <ArgonBox style={selectedDotStyles}></ArgonBox>
      </ArgonBox>
    </Option>
  );
};

const CustomSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <ArgonBox display="flex" alignItems="center" gap="0.5rem">
        <ArgonBox component="img" src={props?.data?.icon || masterCardIcon} />

        <ArgonTypography
          opacity={0.9}
          fontWeight="regular"
          style={{ color: "#8392AB" }}
          fontSize="medium"
        >
          {props?.data?.label}
        </ArgonTypography>
      </ArgonBox>
    </components.SingleValue>
  );
};

function PaymentMethodSelect({ paymentMethods, handlePaymentMethodChange }) {
  return (
    <ArgonSelect
      size="medium"
      name="paymentMethod"
      options={paymentMethods}
      components={{ Option: CustomOptions, SingleValue: CustomSingleValue }}
      onChange={handlePaymentMethodChange}
      value={paymentMethods[0]}
    ></ArgonSelect>
  );
}

export default PaymentMethodSelect;

PaymentMethodSelect.defaultProps = {
  paymentMethods: [],
  handlePaymentMethodChange: null,
  paymentMethod: null,
};

PaymentMethodSelect.propTypes = {
  paymentMethods: PropTypes.any,
  handlePaymentMethodChange: PropTypes.func,
};

CustomOptions.defaultProps = {
  data: "",
  isSelected: "",
};

CustomOptions.propTypes = {
  data: PropTypes.object,
  isSelected: PropTypes.any,
};

CustomSingleValue.defaultProps = {
  data: "",
  label: "",
};

CustomSingleValue.propTypes = {
  data: PropTypes.object,
  label: PropTypes.string,
};
