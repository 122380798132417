import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { resolveConfig } from "prettier";

const useUpdatePasswordService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();

    const updatePassword = async (currentPass, password, passwordConfirmation) => {
        try {
            const response = await axiosRequest.put(
                'users/update_password.json',
                {
                    current_password: currentPass,
                    password: password,
                    password_confirmation: passwordConfirmation
                }
            );
            return response;
        } catch (error) {
            console.error(error);  // Log the full error object for debugging
            toast.error(error.response?.data?.error || 'An error occurred');
            return false;
        }
    };

    return {
        updatePassword
    };
};

export default useUpdatePasswordService;
