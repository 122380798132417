/* eslint-disable react/prop-types */
import React from "react";
import PackageForm from "./packageForm"
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
function AddPackage() {
  return(
            <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Packages / New Package"}/>
    <PackageForm 
      heading={"Add New Package"}
      buttonText={"Add"}
    />
    </DashboardLayout>
  )
}

export default AddPackage