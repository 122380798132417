import React from "react";
// Argon Dashboard 2 PRO MUI example components
import { useSelector } from "react-redux";
import useClinicService from "service-hooks/useClinicService";
import usePatientsService from "service-hooks/usePatientsService";
import DataTable from "examples/Tables/DataTable";
import { useState, useEffect, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../../../components/Spinner";
import Header from "blikol-layouts/components/Header";
import useExportCSVService from "service-hooks/useExportCSVService";
import { useParams } from "react-router-dom";
import { formatName, formatEmail } from "parsers/formatParser";

function View() {
  const [status, setStatus] = useState("active");
  const { user, token } = useSelector((state) => state.auth);
  const userRole = user?.role;
  const [data, setData] = useState({ patientsData: [] });
  const navigate = useNavigate();
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [totalEntries, setTotalEntries] = useState();
  const [totalPages, setTotalPages] = useState();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState({
    country: "",
    city: "",
    gender: "",
    tinnitus_type: "",
  });
  const [loading, setLoading] = useState(true);
  const [listTab, setListTab] = useState();
  const [listOfCountries, setListOfCountries] = useState([]);
  const [listOfCities, setListOfCities] = useState([]);
  const exportCSVServiceHook = useExportCSVService();
  const clinicHookService = useClinicService();
  const patientHookService = usePatientsService();

  const [clinicId, setClinicId] = useState(useParams().id);

  useMemo(() => {
    clinicHookService.countries(clinicId).then((result) => {
      if (result) {
        setListOfCountries(result);
      }
    });
    clinicHookService.cities(clinicId).then((result) => {
      if (result) {
        setListOfCities(result);
      }
    });
  }, [pageRefresh]);

  useEffect(() => {
    clinicHookService
      .patientList(
        clinicId,
        pageNo,
        entriesPerPage,
        selectedOptions["gender"],
        search,
        selectedOptions["tinnitus_type"],
        status,
        selectedOptions["country"],
        selectedOptions["city"]
      )
      .then((result) => {
        if (result && result.patientsData && result.patientsMeta) {
          setData(result);
          setLoading(false);
          setTotalEntries(result?.patientsMeta?.total_count);
          setTotalPages(result?.patientsMeta?.total_pages);
        } else {
          toast.error("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        // Handle the error (e.g., display an error message)
        toast.error("Error fetching user data and attributes:", error);
      });
  }, [
    search,
    entriesPerPage,
    pageNo,
    pageRefresh,
    selectedOptions["gender"],
    selectedOptions["tinnitus_type"],
    selectedOptions["country"],
    selectedOptions["city"],
    status,
  ]);

  const handleView = (id) => {
    navigate(`/${userRole}/clinics/patients/view/${id}`);
  };

  const handleTabChange = (selectedTab) => {
    setStatus(selectedTab);
  };

  const customIcons = [
    {
      action: handleView,
      icon: <RemoveRedEyeOutlinedIcon />,
    },
  ];

  const handleStatusChange = async (id, newStatus) => {
    try {
      const updateResponse = await patientHookService.updateStatus(newStatus, id);
      if (updateResponse) {
        const actionText = newStatus === "active" ? "re-activated" : "deactivated";
        toast.success(`Patient with ID ${id} ${actionText} successfully.`);
        setPageRefresh(!pageRefresh);
      } else {
        const actionText = newStatus === "active" ? "Error re-activating" : "Error deactivating";
        toast.error(`${actionText} patient with ID ${id}.`);
      }
    } catch (error) {
      const actionText = newStatus === "active" ? "Error re-activating" : "Error deactivating";
      toast.error(`${actionText} patient with ID ${id}:`, error);
    }
  };

  const filters = [
    {
      name: "Gender",
      action: (selectedValue) => handleFilterChange("gender", selectedValue),
      listOfOptions: ["male", "female", "Other"],
    },
    {
      name: "Tinnitus Type",
      action: (selectedValue) => handleFilterChange("tinnitus_type", selectedValue),
      listOfOptions: ["tonal", "complex_noise", "other"],
    },
    {
      name: "City",
      action: (selectedValue) => handleFilterChange("city", selectedValue),
      listOfOptions: listOfCities?.cities?.data,
    },
    {
      name: "Country",
      action: (selectedValue) => handleFilterChange("country", selectedValue),
      listOfOptions: listOfCountries?.countries?.data,
    },
  ];

  const threeDotsActive = [
    {
      type: "Deactivate",
      action: (id) => handleStatusChange(id, "inactive"),
      style: {
        color: "red",
      },
    },
  ];

  const threeDotsInactive = [
    {
      type: "Reactivate",
      action: (id) => handleStatusChange(id, "active"),
    },
  ];

  const handleFilterChange = (filterName, selectedValue) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [filterName]: selectedValue,
    }));
  };

  const handleExportCSV = () => {
    const pathname = `/administrator_apis/clinics/${clinicId}/export_patients?status=${status == "active" ? "" : status
      }&query=${search}&country=${selectedOptions["country"]}&city=${selectedOptions["city"]
      }&gender=${selectedOptions["gender"]}&tinnitus_type=${selectedOptions["tinnitus_type"]}`;
    const filename = `admin_clinic_patients`;
    exportCSVServiceHook.exportCSV(pathname, filename);
  };

  const tableCell = (option) => {
    if (option && option.trim() !== "") {
      return option
        .replace(/[_-]/g, " ")
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    } else {
      return "";
    }
  };

  const patientsRowsData = data.patientsData.map((patient, index) => ({
    ...patient,
    gender: tableCell(patient?.gender),
    tinnitus_type: tableCell(patient?.tinnitus_type),
    full_name: formatName(patient?.full_name),
    email: formatEmail(patient?.email),
    action: (
      <ActionMenu
        threeDots={patient.status === "inactive" ? threeDotsInactive : threeDotsActive}
        row={patient}
        customIcons={customIcons}
        key={index + 1}
      />
    ),
  }));

  const tableData = {
    columns: [
      { Header: "name", accessor: "full_name" },
      { Header: "email", accessor: "email" },
      { Header: "gender", accessor: "gender" },
      { Header: "tinnitus type", accessor: "tinnitus_type" },
      { Header: "country", accessor: "country" },
      { Header: "city", accessor: "city" },
      { Header: "action", accessor: "action" },
    ],
    rows: patientsRowsData,
  };

  return (
    <>
      {loading && <Spinner />}
      {!loading && (
        <DataTable
          canExportCSV={{
            name: "Export as CSV",
            action: handleExportCSV,
          }}
          showEntriesPerPage={false}
          hasTabHeader={true}
          onTabChangeValue={handleTabChange}
          tabList={["Active", "Inactive"]}
          canSearch
          canFilter
          filters={filters}
          dataTableName={"Patients"}
          entriesPerPage={entriesPerPage}
          setSearch={setSearch}
          search={search}
          totalPages={totalPages}
          setEntriesPerPage={setEntriesPerPage}
          pageNo={pageNo}
          setPageNo={setPageNo}
          totalEntries={totalEntries}
          table={tableData}
          viewEntry={handleView}
          clickableRecords={true}
        />
      )}
    </>
  );
}

export default View;
