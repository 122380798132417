import React, { useState, useEffect, useRef } from "react";
import "rc-slider/assets/index.css";
import ArgonButton from "components/ArgonButton";
import { useSelector } from "react-redux";
import "./FrequencyGenerator.css";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonBox from "components/ArgonBox";
import CountdownTimer from "../CountDownTimer";
import FrequencySlider from "../FrequencySlider";
import { useDispatch } from "react-redux";
import { setNextStep } from "slices/generalSlice";
import { useParams } from "react-router-dom";
import usePatientSessionService from "service-hooks/clinic/usePatientSessionService";
import { toast } from "react-toastify";
import Spinner from "blikol-layouts/components/Spinner";
import { useNavigate } from "react-router-dom";
import { FREQUENCY_TEST } from "constants";
import { FREQUENCY_CONTROLS_TYPE } from "constants";
import { FREQUENCY_BAR_COLORS } from "constants";
import { DEFAULT_FREQUENCY_TIMER } from "constants";

const FrequencyGenerator = () => {
  const percentageErrorAllowed = 20;
  const dispatch = useDispatch();
  const patientSessionsHookService = usePatientSessionService();
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.user?.role);

  const { patient_id } = useParams();
  const [resetTimer, setResetTimer] = useState(false);
  const [isTunePlaying, setIsTunePlaying] = useState(false);

  const [initalSearchFrequency, setInitialSearchFrequency] = useState(6000);
  const [zoomingFrequuency, setZoomingFrequency] = useState(0);
  const [fineTuningFrequency, setFineTuningFrequency] = useState(0);

  const [secondTestFrequency, setSecondTestFrequency] = useState(6000);

  const [minInitialFrequency, setMinInitialFrequency] = useState(500);
  const [maxInitalFrequency, setMaxInitialFrequency] = useState(12000);

  const [minZoomingFrequency, setMinZoomingFrequency] = useState(500);
  const [maxZoomingFrequency, setMaxZoomingFrequency] = useState(12000);

  const [minFineTuningFrequency, setMinFineTuningFrequency] = useState(500);
  const [maxFineTuningFrequency, setMaxFineTuningFrequency] = useState(12000);

  const [showZoomingFrequencyBar, setShowZoomingFrequencyBar] = useState(false);
  const [showFineTuningFrequencyBar, setShowFineTuningFrequencyBar] = useState(false);

  const [showSecondTest, setShowSecondTest] = useState(false);

  const [firstTestResult, setFirstTestResult] = useState(null);

  const [finalTestLowerLimit, setFinalTestLowerLimit] = useState(500);

  const [finalTestUpperLimit, setFinalTestUpperLimit] = useState(12000);

  const [resetKey, setResetKey] = useState(0);
  const [backgroundGradient, setBackgroundGradient] = useState(FREQUENCY_BAR_COLORS.secondTest);

  const [frequencyInput, setFrequencyInput] = useState(false);

  const [testResult, setTestResult] = useState(null);

  const [loading, setLoading] = useState(false);

  const [frequencyErrorPercentage, setFrequencyErrorPercentage] = useState(null);

  const [frequencyDuration, setFrequencyDuration] = useState(DEFAULT_FREQUENCY_TIMER);

  const [isTestSkipped, setIsTestSkipped] = useState(false);

  const [historyStack, setHistoryStack] = useState([
    {
      backgroundGradient: FREQUENCY_BAR_COLORS.secondTest,
      lowerLimit: 500,
      upperLimit: 12000,
      frequency: 6000,
    },
  ]);

  const [note, setNote] = useState("");

  const audioContext = useRef(null);

  const oscillator = useRef(null);

  const onTuneStop = () => {
    setIsTunePlaying(false);
  };
  const playAudio = (frquencyValue) => {
    setResetKey((prevKey) => prevKey + 1);
    setResetTimer(true);
    audioContext.current = new (window.AudioContext || window.webkitAudioContext)();

    oscillator.current = audioContext.current.createOscillator();
    oscillator.current.type = "sine";
    oscillator.current.frequency.setValueAtTime(frquencyValue, audioContext.current.currentTime);
    oscillator.current.connect(audioContext.current.destination);
    oscillator.current.onended = onTuneStop;
    setIsTunePlaying(true);
    oscillator.current.start();
    const duration = Math.round(frequencyDuration / 1000);
    oscillator.current.stop(audioContext.current.currentTime + duration);
    setIsTestSkipped(false);
  };

  const handleStopAudio = () => {
    oscillator.current.stop();
    setResetTimer(false);
  };

  const handleInitialSearchFrequencyChange = (frequency) => {
    setInitialSearchFrequency(frequency);
    setFineTuningFrequency(0);
  };

  const handleZoomingFrequencyChange = (frequency) => {
    setZoomingFrequency(frequency);
  };

  const handleFineTuningFrequencyChange = (frequency) => {
    setFineTuningFrequency(frequency);
  };

  const calculateMinMaxLimitsFromNewFrequency = (frequency, percentage) => {
    const min =
      Math.round(frequency - (frequency * percentage) / 100) >= 500
        ? Math.round(frequency - (frequency * percentage) / 100)
        : 500;
    const max =
      Math.round(frequency + (frequency * percentage) / 100) <= 12000
        ? Math.round(frequency + (frequency * percentage) / 100)
        : 12000;

    return { min, max };
  };

  const findMidpoint = (min, max) => {
    return Math.round((min + max) / 2);
  };

  const onAfterChangeInitalSearchFrequency = (frequency) => {
    setShowZoomingFrequencyBar(true);
    setShowFineTuningFrequencyBar(false);
    playAudio(frequency);

    const { min, max } = calculateMinMaxLimitsFromNewFrequency(frequency, 50);
    setMinZoomingFrequency(min);
    setMaxZoomingFrequency(max);
    const midpoint = findMidpoint(min, max);
    setZoomingFrequency(midpoint);
    setFirstTestResult(null);
    setShowSecondTest(false);
  };

  const onAfterChangeZoomingFrequency = (frequency) => {
    setShowFineTuningFrequencyBar(true);
    playAudio(frequency);

    const { min, max } = calculateMinMaxLimitsFromNewFrequency(frequency, 15);

    setMinFineTuningFrequency(min);
    setMaxFineTuningFrequency(max);
    const midpoint = findMidpoint(min, max);
    setFineTuningFrequency(midpoint);
    setShowSecondTest(true);
  };

  const onAfterFineTuningFrequencyChange = (frequency) => {
    playAudio(frequency);
  };

  const createLinerGradientColor = (startPoint, endPoint) => {
    return (
      "linear-gradient(to right, grey " +
      startPoint +
      "%, #dff5fb " +
      startPoint +
      "%, #dff5fb " +
      endPoint +
      "%, grey " +
      endPoint +
      "%)"
    );
  };

  const handleUndo = () => {
    const historyStackClone = structuredClone(historyStack);

    if (!historyStackClone?.length) {
      return;
    }
    const historyObj = historyStackClone.at(-2);

    setBackgroundGradient(historyObj?.backgroundGradient || FREQUENCY_BAR_COLORS.secondTest);
    historyObj?.upperLimit && setFinalTestUpperLimit(historyObj.upperLimit);
    historyObj?.lowerLimit && setFinalTestLowerLimit(historyObj.lowerLimit);
    historyObj?.frequency && setSecondTestFrequency(historyObj.frequency);

    if (historyStackClone?.length > 1) {
      historyStackClone.pop();
    }
    setHistoryStack(historyStackClone);
  };

  const recordHistory = (gradientColor, lowerLimit, upperLimit, frequency) => {
    const history = {
      backgroundGradient: gradientColor,
      lowerLimit: lowerLimit,
      uppperLimit: upperLimit,
      frequency: frequency,
    };

    setHistoryStack((prevHistory) => setHistoryStack([...prevHistory, history]));
  };

  const onAfterFinalTestFrequencyChange = (frequency, actionType) => {
    if (actionType === FREQUENCY_CONTROLS_TYPE.lower) {
      const midpointFrequency = findMidpoint(secondTestFrequency, finalTestLowerLimit);

      const startPoint = ((finalTestLowerLimit - 500) / (12000 - 500)) * 100;
      const endPoint = ((secondTestFrequency - 500) / (12000 - 500)) * 100;

      let color = createLinerGradientColor(startPoint, endPoint);

      setBackgroundGradient(color);
      setFinalTestUpperLimit(secondTestFrequency);
      setSecondTestFrequency(midpointFrequency);

      recordHistory(color, "", secondTestFrequency, midpointFrequency);

      playAudio(midpointFrequency);
    } else if (actionType === FREQUENCY_CONTROLS_TYPE.higher) {
      const midpointFrequency = findMidpoint(secondTestFrequency, finalTestUpperLimit);

      const startPoint = ((secondTestFrequency - 500) / (12000 - 500)) * 100;
      const endPoint = ((finalTestUpperLimit - 500) / (12000 - 500)) * 100;

      let color = createLinerGradientColor(startPoint, endPoint);

      setBackgroundGradient(color);

      setFinalTestLowerLimit(secondTestFrequency);
      setSecondTestFrequency(midpointFrequency);

      recordHistory(color, secondTestFrequency, "", midpointFrequency);

      playAudio(midpointFrequency);
    } else {
      const { min, max } = calculateMinMaxLimitsFromNewFrequency(frequency, 50);

      const startPoint = ((min - 500) / (12000 - 500)) * 100;
      const endPoint = ((max - 500) / (12000 - 500)) * 100;

      let color = createLinerGradientColor(startPoint, endPoint);

      setBackgroundGradient(color);
      setFinalTestUpperLimit(max);
      setFinalTestLowerLimit(min);
      setSecondTestFrequency(frequency);

      recordHistory(color, min, max, frequency);

      playAudio(frequency);
    }
  };

  const handleFrequencyInputSubmit = () => {
    const frequencyValue = Number(frequencyInput);
    if ((frequencyValue >= 500 && frequencyValue <= 12000) || !frequencyValue) {
      if (firstTestResult) {
        if (!frequencyValue) {
          playAudio(secondTestFrequency);
        } else {
          onAfterFinalTestFrequencyChange(frequencyValue);
        }
      } else if (showFineTuningFrequencyBar) {
        //touch only fine tuning bar
        if (!frequencyValue) {
          playAudio(fineTuningFrequency);
        } else {
          if (
            frequencyValue >= minFineTuningFrequency &&
            frequencyValue <= maxFineTuningFrequency
          ) {
            setFineTuningFrequency(frequencyValue);
            onAfterFineTuningFrequencyChange(frequencyValue);
          }
        }
      } else if (showZoomingFrequencyBar) {
        //touch only zooming bar
        if (!frequencyValue) {
          playAudio(zoomingFrequuency);
        } else {
          if (frequencyValue >= minZoomingFrequency && frequencyValue <= maxZoomingFrequency) {
            setZoomingFrequency(frequencyValue);
            onAfterChangeZoomingFrequency(frequencyValue);
          }
        }
      } else {
        //touch only inital frequency bar
        if (!frequencyValue) {
          playAudio(initalSearchFrequency);
        } else {
          if (frequencyValue >= minInitialFrequency && frequencyValue <= maxInitalFrequency) {
            setInitialSearchFrequency(frequencyValue);
            onAfterChangeInitalSearchFrequency(frequencyValue);
          }
        }
      }
      setFrequencyInput("");
    } else {
      setFrequencyInput("");
    }
  };
  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      handleFrequencyInputSubmit();
    }
  };

  const handleInputFrequencyChange = (e) => {
    const frequencyValue = Number(e.target.value);
    if (!frequencyValue) {
      setFrequencyInput("");
      return;
    }

    if (
      !firstTestResult &&
      frequencyValue >= minInitialFrequency &&
      frequencyValue <= maxInitalFrequency
    ) {
      setIsTestSkipped(true);
    } else {
      setIsTestSkipped(false);
    }

    setFrequencyInput(frequencyValue);
  };

  const handleReset = () => {
    if (firstTestResult) {
      setSecondTestFrequency(6000);
      setFinalTestLowerLimit(500);
      setFinalTestUpperLimit(12000);
      setTestResult(null);
      setIsTestSkipped(false);
      setBackgroundGradient(FREQUENCY_BAR_COLORS.secondTest);
    } else {
      setInitialSearchFrequency(6000);
      setZoomingFrequency(0);
      setFineTuningFrequency(0);
      setShowZoomingFrequencyBar(false);
      setShowFineTuningFrequencyBar(false);
      setMinFineTuningFrequency(500);
      setMinZoomingFrequency(500);
      setMinFineTuningFrequency(500);
      setMaxInitialFrequency(12000);
      setMaxZoomingFrequency(12000);
      setMaxFineTuningFrequency(12000);
      setFirstTestResult(null);
      setSecondTestFrequency(6000);
      setShowSecondTest(false);
      setIsTestSkipped(false);
    }
  }; // Handle the error (e.g., display an error message)
  const handleNext = () => {
    if (isTestSkipped) {
      setFirstTestResult(frequencyInput);
      setIsTestSkipped(false);
      setFrequencyInput("");
      setShowSecondTest(true);
      dispatch(setNextStep());
      return;
    }
    if (firstTestResult) {
      const percentageError =
        (Math.abs(firstTestResult - secondTestFrequency) / firstTestResult) * 100;

      if (percentageError >= (Number(frequencyErrorPercentage) || 20)) {
        setTestResult({ status: "failed", value: 0 });
        dispatch(setNextStep());
      } else {
        const diagnosedFrequency = Math.floor((firstTestResult + secondTestFrequency) / 2);

        setTestResult({ status: "passed", value: diagnosedFrequency });
        dispatch(setNextStep());
      }
    } else {
      if (showSecondTest) {
        setFirstTestResult(fineTuningFrequency);
        dispatch(setNextStep());
      }
    }
  };

  const handleLowerControl = () => {
    onAfterFinalTestFrequencyChange("", FREQUENCY_CONTROLS_TYPE.lower);
  };

  const handleHigherControl = () => {
    onAfterFinalTestFrequencyChange("", FREQUENCY_CONTROLS_TYPE.higher);
  };

  const handleStartAgain = () => {
    window.location.reload();
  };

  const handleNoteChange = (e) => {
    //do validation if required
    setNote(e.target.value);
  };

  const handleSaveFrequency = () => {
    setLoading(true);
    patientSessionsHookService
      .createPatientSession({ frequency: testResult?.value, note: note, patient_id })
      .then((result) => {
        if (result) {
          setLoading(false);
          navigate(`/${userRole}/patients/view/${patient_id}`);
        } else {
          setLoading(false);
          toast.error("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error creating entry in database:", error);
      });
  };

  useEffect(() => {
    //call API to get percentage error and maintain the state variable
    setLoading(true);
    patientSessionsHookService
      .getFrequencyTestPercentageError()
      .then((result) => {
        if (result) {
          setFrequencyErrorPercentage(result?.frequency_difference);
          setFrequencyDuration(
            Number(result?.frequency_duration) * 1000 || DEFAULT_FREQUENCY_TIMER
          );
          setLoading(false);
        } else {
          setLoading(false);
          toast.error("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error("Error fetching user data and attributes:", error);
      });
    return () => {
      if (oscillator.current) {
        oscillator.current.stop();
        oscillator.current.disconnect();
      }
    };
  }, []);
  return (
    <>
      {loading ? (
        <Spinner />
      ) : testResult ? (
        <>
          <ArgonBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            gap="1rem"
          >
            <ArgonTypography>First Test Result: {firstTestResult}</ArgonTypography>
            <ArgonTypography>Second Test Result: {secondTestFrequency}</ArgonTypography>
            {testResult?.status === "failed" && (
              <ArgonTypography fontWeight="bold">Test Status: Failed</ArgonTypography>
            )}

            {testResult?.status === "passed" && (
              <>
                <ArgonTypography fontWeight="bold">
                  Frequency Diagnosed: {testResult?.value}
                </ArgonTypography>
                <ArgonInput
                  name="note"
                  onChange={(e) => handleNoteChange(e)}
                  placeholder="Enter Note"
                  value={note}
                  multiline
                  rows={4}
                />
              </>
            )}
            <ArgonBox display="flex" justifyCOntent="space-between" gap="1rem">
              <ArgonButton variant="outlined" onClick={handleStartAgain} color="info">
                Start Again
              </ArgonButton>
              {testResult?.status === "passed" && (
                <ArgonButton onClick={handleSaveFrequency} color="info">
                  Save Frequency
                </ArgonButton>
              )}
            </ArgonBox>
          </ArgonBox>
        </>
      ) : (
        <>
          {initalSearchFrequency > 0 && !firstTestResult && (
            <FrequencySlider
              sliderIdentifier={FREQUENCY_TEST.initial}
              id="initial-frquency-slider"
              title="Initial Search"
              backgroundColor={FREQUENCY_BAR_COLORS.initialTest}
              frequency={initalSearchFrequency}
              setFrequency={handleInitialSearchFrequencyChange}
              onAfterChange={onAfterChangeInitalSearchFrequency}
              setIsTunePlaying={setIsTunePlaying}
              isTunePlaying={isTunePlaying}
              minFrequency={minInitialFrequency}
              maxFrequency={maxInitalFrequency}
            />
          )}
          {showZoomingFrequencyBar && !firstTestResult && (
            <FrequencySlider
              sliderIdentifier={FREQUENCY_TEST.zooming}
              id="zooming-frquency-slider"
              title="Zooming"
              backgroundColor={FREQUENCY_BAR_COLORS.zoomingTest}
              frequency={zoomingFrequuency}
              setFrequency={handleZoomingFrequencyChange}
              onAfterChange={onAfterChangeZoomingFrequency}
              setIsTunePlaying={setIsTunePlaying}
              isTunePlaying={isTunePlaying}
              minFrequency={minZoomingFrequency}
              maxFrequency={maxZoomingFrequency}
            />
          )}

          {showFineTuningFrequencyBar && !firstTestResult && (
            <FrequencySlider
              sliderIdentifier={FREQUENCY_TEST.fineTuning}
              id="fine-tuning-frquency-slider"
              title="Fine Tuning"
              backgroundColor={FREQUENCY_BAR_COLORS.fineTuningTest}
              frequency={fineTuningFrequency}
              setFrequency={handleFineTuningFrequencyChange}
              onAfterChange={onAfterFineTuningFrequencyChange}
              setIsTunePlaying={setIsTunePlaying}
              isTunePlaying={isTunePlaying}
              minFrequency={minFineTuningFrequency}
              maxFrequency={maxFineTuningFrequency}
            />
          )}
          {firstTestResult && (
            <div className="final-test-slider">
              <FrequencySlider
                sliderIdentifier={FREQUENCY_TEST.secondTest}
                id="final-test-frquency-slider"
                sliderTitle="SECOND_TEST"
                backgroundGradient={backgroundGradient}
                frequency={secondTestFrequency}
                setFrequency={setSecondTestFrequency}
                onAfterChange={onAfterFinalTestFrequencyChange}
                setIsTunePlaying={setIsTunePlaying}
                isTunePlaying={isTunePlaying}
                minFrequency={500}
                maxFrequency={12000}
                firstTestResult={firstTestResult}
              />
            </div>
          )}
          <ArgonBox mt={15} p={1} className="frequency-controls">
            <ArgonBox display="flex" justifyContent="center">
              {" "}
              {resetTimer ? (
                <CountdownTimer
                  resetTimer={resetTimer}
                  key={resetKey}
                  frequencyDuration={frequencyDuration}
                />
              ) : (
                <ArgonTypography fontWeight="bold">0:0</ArgonTypography>
              )}
            </ArgonBox>

            <ArgonBox display="grid" gridTemplateColumns="1fr" justifyItems="center" mt={3}>
              <ArgonBox display="flex" gap="1rem" mb="1rem">
                <ArgonBox>
                  <ArgonInput
                    onChange={(e) => handleInputFrequencyChange(e)}
                    onKeyDown={handleEnterKeyPress}
                    value={frequencyInput}
                    type="number"
                    min="500"
                    max="100"
                    placeholder="500 - 12000"
                  />
                </ArgonBox>

                <ArgonButton
                  disabled={isTunePlaying}
                  onClick={handleFrequencyInputSubmit}
                  color="info"
                >
                  Enter Frequency
                </ArgonButton>
              </ArgonBox>
              <ArgonBox display="flex" gap="1rem" flexDirection="column" width="10rem">
                {firstTestResult && (
                  <ArgonBox display="flex" gap="1rem">
                    <ArgonButton disabled={isTunePlaying} onClick={handleLowerControl} color="info">
                      Lower
                    </ArgonButton>
                    <ArgonButton
                      disabled={isTunePlaying}
                      onClick={handleHigherControl}
                      color="info"
                    >
                      Higher
                    </ArgonButton>
                  </ArgonBox>
                )}

                {!isTunePlaying && (
                  <ArgonButton
                    disabled={isTunePlaying}
                    onClick={handleFrequencyInputSubmit}
                    color="info"
                  >
                    Play
                  </ArgonButton>
                )}

                {isTunePlaying && (
                  <ArgonButton disabled={!isTunePlaying} onClick={handleStopAudio} color="error">
                    Stop
                  </ArgonButton>
                )}

                {firstTestResult && (
                  <ArgonButton disabled={isTunePlaying} onClick={handleUndo} color="success">
                    Undo
                  </ArgonButton>
                )}

                <ArgonButton
                  disabled={isTunePlaying}
                  onClick={handleReset}
                  color="info"
                  variant="outlined"
                >
                  Reset
                </ArgonButton>

                {(showSecondTest || isTestSkipped) && (
                  <ArgonButton
                    disabled={isTunePlaying || (!showSecondTest && !isTestSkipped)}
                    onClick={handleNext}
                    color="info"
                  >
                    Next
                  </ArgonButton>
                )}
              </ArgonBox>
            </ArgonBox>
          </ArgonBox>
        </>
      )}
    </>
  );
};

export default FrequencyGenerator;
