import React from 'react'
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ResetPassword from 'blikol-layouts/components/ResetPassword';
import Grid from "@mui/material/Grid";

function index() {
    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Clinics / Change Password"} />
            <Grid container>
                <Grid item lg={12}>
                    <ArgonBox px={3} pt={4} sx={{ display: "flex", flexDirection: "column", gap: "15px" }}>
                        <ArgonTypography
                            sx={{
                                color: "var(--Default, #172B4D)",
                                fontSize: "25px",
                                fontStyle: "normal",
                                fontWeight: "700",
                                lineHeight: "137%",
                                letterSpacing: "-0.8px",
                            }}
                        >
                            Update Password
                        </ArgonTypography>
                        <ArgonBox pt={2}>
                            <ResetPassword />
                        </ArgonBox>
                    </ArgonBox>

                </Grid>
            </Grid>
        </DashboardLayout>

    )
}

export default index