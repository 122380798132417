import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import useSettingService from "service-hooks/useSettingService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

function Session() {
  const settingHookService = useSettingService();
  const [formData, setFormData] = useState({
    length: 0,
    stop_duration: 0,
  });

  useEffect(() => {
    settingHookService.settings().then((result) => {
      if (result) {
        const sessionData = result.data.session;
        setFormData({
          length: sessionData.length || 0,
          stop_duration: sessionData.stop_duration || 0,
        });
      } else {
        console.log("Unable to fetch settings");
      }
    });
  }, []);
  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: parseInt(e.target.value),
    });
  };

  const handleSubmit = () => {
    settingHookService
      .update({ session: formData })
      .then(() => {
        toast.success(`Session settings updated successfully.`);
      })
      .catch((error) => {
        toast.error(error);
      });
  };
  return (
    <Grid item xs={10}>
      <Card sx={{ overflow: "hidden" }}>
        <ArgonBox p={3}>
          <ArgonTypography variant="h5">Session Settings</ArgonTypography>
        </ArgonBox>
        <ArgonBox component="form" pb={3} px={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Session Length (Mins)"
                  placeholder="e.g 30 mins"
                  name="length"
                  value={formData.length}
                  onChange={handleChange}
                  required={true}
                  type={"number"}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item xs={12} sm={6}>
                <FormField
                  label="Session Stop Duration (%)"
                  placeholder="e.g 30 mins"
                  name="stop_duration"
                  value={formData.stop_duration}
                  onChange={handleChange}
                  required={true}
                  type={"number"}
                />
              </Grid>
            </Grid>
          </Grid>
        </ArgonBox>
      </Card>
      <ArgonBox mt={3}>
        <ArgonButton onClick={handleSubmit} color="info">
          Update
        </ArgonButton>
      </ArgonBox>
    </Grid>
  );
}

export default Session;
