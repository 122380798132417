import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useClinicService = () => {
  const dispatch = useDispatch();
  const axiosRequest = useBaseService().axiosRequest();

  const clinics = async (currentPage, itemsPerPage, country = "", query = "", city = "", status = "") => {
    try {
      const response = await axiosRequest.get(
        `administrator_apis/clinics?page=${currentPage}&items_per_page=${itemsPerPage}&country=${country}&query=${query}&city=${city}&status=${status == 'active' ? '' : status}`
      );
      const clinicsData = response.data.clinics;
      const clinicsMeta = response.data.meta;
      return { clinicsMeta, clinicsData };
    } catch (error) {
      toast.error(error.response.data.error);

      return false;
    }
  };

  const countries = async () => {
    try {
      const response = await axiosRequest.get(`administrator_apis/clinics/countries`);
      const countries = response;
      return { countries };
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const cities = async () => {
    try {
      const response = await axiosRequest.get(`administrator_apis/clinics/cities`);
      const cities = response;
      return { cities };
    } catch (error) {
      toast.error(error.response.data.error);

      return false;
    }
  };

  const create = async (clinicData) => {
    try {
      const response = await axiosRequest.post(`administrator_apis/clinics`, clinicData);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const destroy = async (id) => {
    try {
      const response = await axiosRequest.delete(`administrator_apis/clinics/${id}`);
      return response;
    } catch (error) {
      return error.response.data.error;
    }
  };

  const update = async (clinicData, id) => {
    try {
      const response = await axiosRequest.put(`administrator_apis/clinics/${id}`, clinicData);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };

  const resendCredentails = async (id) => {
    try {
      const response = await axiosRequest.get(
        `administrator_apis/clinics/${id}/resend_login_credentials`
      );
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const clinic = async (id) => {
    try {
      const response = await axiosRequest.get(`administrator_apis/clinics/${id}`);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };

  const allClinicIds = async () => {
    try {
      const response = await axiosRequest.get(`administrator_apis/clinics/all_clinic_ids`);
      const clinics = response.data;
      return clinics
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const addBonus = async (clinicId, credits) => {
    try {
      const response = await axiosRequest.post(`administrator_apis/bonus_credits?clinic_id=${clinicId}`, { credits });
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };

  const patientList = async (clinic_id, currentPage, itemsPerPage, gender = "", query = "", tinnitus_type = "", status = "", country = "", city = "") => {
    try {
      const response = await axiosRequest.get(
        `administrator_apis/patients?clinic_id=${clinic_id}&page=${currentPage}&status=${status == 'active' ? '' : status}&items_per_page=${itemsPerPage}&gender=${gender}&query=${query}&tinnitus_type=${tinnitus_type}&country=${country}`
      );
      const patientsData = response.data.patients;
      const patientsMeta = response.data.meta;
      return { patientsData, patientsMeta };
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const patientsCountries = async (id) => {
    try {
      const response = await axiosRequest.get(`administrator_apis/patients/countries?clinic_id=${id}`);
      const countries = response;
      return { countries };
    } catch (error) {
      toast.error(error.response.data.error);
      return false;
    }
  };

  const patientsCities = async (id) => {
    try {
      const response = await axiosRequest.get(`administrator_apis/patients/cities?clinic_id=${id}`);
      const cities = response;
      return { cities };
    } catch (error) {
      toast.error(error.response.data.error);

      return false;
    }
  };
  const updatePatientStatus = async (status = "", id) => {
    try {
      const response = await axiosRequest.put(`administrator_apis/patients/${id}/change_status?status=${status}`);
      return response;
    } catch (error) {
      toast.error(error.response.data.error);
      return false; // You can handle the error as needed in the calling code.
    }
  };



  return {
    clinics,
    cities,
    countries,
    resendCredentails,
    destroy,
    update,
    clinic,
    allClinicIds,
    create,
    patientList,
    patientsCountries,
    patientsCities,
    updatePatientStatus,
    addBonus,
  };
};

export default useClinicService;
