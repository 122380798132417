import React from "react";
// Argon Dashboard 2 PRO MUI example components
import { useSelector } from "react-redux";
import ArgonBox from "components/ArgonBox";
import useClinicRequestService from "service-hooks/useClinicRequestService";
import DataTable from "examples/Tables/DataTable";
import { useState, useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import { useNavigate } from "react-router-dom";
import "../../style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../components/Spinner";
import TextAreaModal from "../../../components/TextAreaModal";
import useExportCSVService from "service-hooks/useExportCSVService";
import ConfirmationModal from "examples/ConfirmationModal";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import ActionMenu from "blikol-layouts/components/ActionMenu";

function Requests() {
  const userRole = useSelector((state) => state.auth?.user?.role);
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [search, setSearch] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalEntries, setTotalEntries] = useState();
  const [totalPages, setTotalPages] = useState();
  const [pageRefresh, setPageRefresh] = useState(false);
  const [listOfCountries, setListOfCountries] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [listOfCities, setListOfCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const exportCSVServiceHook = useExportCSVService();
  const clinicRequestHookService = useClinicRequestService();

  useMemo(() => {
    clinicRequestHookService.countries().then((result) => {
      if (result) {
        setListOfCountries(result);
      }
    });
    clinicRequestHookService.cities().then((result) => {
      if (result) {
        setListOfCities(result);
      }
    });
  }, [pageRefresh]);

  useEffect(() => {
    clinicRequestHookService
      .requests(pageNo, entriesPerPage, selectedOptions["Country"], search, selectedOptions["City"])
      .then((result) => {
        if (result) {
          setData(result.clinic_requests);
          setLoading(false);
          setTotalEntries(result?.meta?.total_count);
          setTotalPages(result?.meta?.total_pages);
        } else {
          console.log("Data is undefined or incomplete.");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.error);
      });
  }, [
    search,
    entriesPerPage,
    pageNo,
    pageRefresh,
    selectedOptions["Country"],
    selectedOptions["City"],
    search,
  ]);

  const handleFilterChange = (filterName, selectedValue) => {
    setSelectedOptions((prevOptions) => ({
      ...prevOptions,
      [filterName]: selectedValue,
    }));
  };

  const handleExportCSV = () => {
    const pathname = `/administrator_apis/clinic_requests/export`;
    const filename = `clinic_requests`;
    exportCSVServiceHook.exportCSV(pathname, filename);
  };


  // Constants for filter and actions binded
  const filters = [
    {
      name: "Country",
      action: (selectedValue) => handleFilterChange("Country", selectedValue),
      listOfOptions: listOfCountries,
    },
    {
      name: "City",
      action: (selectedValue) => handleFilterChange("City", selectedValue),
      listOfOptions: listOfCities,
    },
  ];

  const handleView = (id) => {
    navigate(`/${userRole}/clinics/requests/view/${id}`);
  };

  const customIcons = [
    {
      action: handleView,
      icon: <RemoveRedEyeOutlinedIcon />,
    },
  ];

  const statusColumn = (status) => {
    return (
      <>
        <div className={`status-${status}`}>{(status === "pending") ? `Awaiting` : status}</div>
      </>
    );
  };


  const requestsRowsData = data.map((clinicRequest, index) => ({
    ...clinicRequest,
    status: statusColumn(clinicRequest?.status),
    action: <ActionMenu
      row={clinicRequest}
      customIcons={customIcons}
      key={index + 1}
    />,
  }));

  // Data table
  const tableData = {
    columns: [
      { Header: "request id", accessor: "registration_id" },
      { Header: "Applied date", accessor: "applied_date" },
      { Header: "name", accessor: "name" },
      { Header: "email", accessor: "email" },
      { Header: "country", accessor: "country" },
      { Header: "city", accessor: "city" },
      { Header: "status", accessor: "status" },
      { Header: "action", accessor: "action" },
    ],
    rows: requestsRowsData,
  };

  return (
    <>
      <Card p={1}>
        <ArgonBox>
          {loading && <Spinner />}
          {!loading && (
            <DataTable
              canExportCSV={{
                name: "Export as CSV",
                action: handleExportCSV,
              }}
              canSearch
              canFilter
              filters={filters}
              dataTableName={"Clinics"}
              entriesPerPage={entriesPerPage}
              setSearch={setSearch}
              search={search}
              totalPages={totalPages}
              setEntriesPerPage={setEntriesPerPage}
              pageNo={pageNo}
              setPageNo={setPageNo}
              totalEntries={totalEntries}
              table={tableData}
              customIcons={customIcons}
              viewEntry={handleView}
              clickableRecords={true}

            />
          )}
        </ArgonBox>
      </Card>
    </>
  );
}

export default Requests;
