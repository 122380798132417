import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PersonIcon from "../../../../../assets/images/icons/patients/person-icon.svg"
import usePatientsService from "service-hooks/clinic/usePatientsService";
import Spinner from "../../../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ArgonButton from "components/ArgonButton";
import "../../style.css"
import ResetConfirmModal from "../ResetConfirmModal";
import { formatDate, formatTimestamp } from "parsers/formatParser";

function ViewPatient() {
    const navigate = useNavigate();
    const patientHookService = usePatientsService();
    const [loading, setLoading] = useState(true);
    const [patientData, setPatientData] = useState();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [patientId, setPatientId] = useState(useParams().id);
    const [showResetConfirmation, setShowResetConfirmation] = useState(false);
    const [resetItemId, setResetItemId] = useState(null);
    const [currentFrequency, setCurrentFrequency] = useState()

    useEffect(() => {
        patientHookService
            .patient(patientId)
            .then((result) => {
                if (result) {
                    const patient = result.data;
                    setLoading(false);
                    setPatientId(patient?.id);
                    setPatientData(
                        result.data
                    );
                    setCurrentFrequency(patient.current_frequency)
                } else {
                    toast.error("No Patient with this id");
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    }, []);

    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }
    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        textTransform: "capitalize",

    }
    const fieldEmailValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    function frequencyFormat(value) {
        return value !== null ? `${value} Hz` : null;
    }


    const handleResetDiagnosis = () => {
        if (resetItemId) {
            patientHookService
                .resetDiagnosis(resetItemId)
                .then(() => {
                    setCurrentFrequency("")
                    toast.success('Diagnosis reset successfully.');
                })
                .catch((error) => {
                    toast.error(error);
                });
        }
        setShowResetConfirmation(false);
    };

    const handleReset = () => {
        if (patientData?.sessions.length > 0) {
            setResetItemId(patientData?.id);
            setShowResetConfirmation(true);
        } else {
            toast.error('No sessions available to reset.');
        }
    };


    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Patients / Patient Detail"} />
            <ArgonBox px={3} pt={3}>
                <ArgonBox sx={{ display: "flex", justifyContent: "space-between" }}>
                    <ArgonTypography
                        mb={3}
                        mt={4}
                        sx={{
                            color: "var(--Default, #172B4D)",
                            fontSize: "25px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "137%",
                            letterSpacing: "-0.8px",
                        }}
                    >
                        Patient Details
                    </ArgonTypography>
                    <ArgonTypography
                        mb={3}
                        mt={4}
                        sx={{
                            color: 'var(--Label, #8392AB)',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                        }}
                    >
                        Current Frequency:
                        <span className="current-frequency-value">
                            {frequencyFormat(currentFrequency)}
                        </span>
                    </ArgonTypography>
                </ArgonBox>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={4} >
                        <Card
                            sx={{
                                borderRadius: '20px',
                                background: '#FFF',
                                boxShadow: '0px 5px 14px 0px rgba(0, 0, 0, 0.05)',
                            }}
                        >
                            <ArgonBox
                                sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                    alignItems: "center"
                                }}
                            >
                                <ArgonBox sx={{ padding: "30px" }}>
                                    <img src={PersonIcon} alt="PersonIcon" />
                                </ArgonBox>
                                <ArgonBox sx={{ display: "flex", flexDirection: "column" }}>
                                    <ArgonTypography
                                        sx={{
                                            color: "#000",
                                            fontSize: "24px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "normal",
                                        }}
                                        mb={0.5}
                                    >
                                        {patientData?.full_name}
                                    </ArgonTypography>
                                    <ArgonTypography
                                        sx={{
                                            color: "#000",
                                            fontSize: "14px",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            lineHeight: "normal",
                                            textTransform: "capitalize"
                                        }}
                                    >
                                        {patientData?.gender}

                                    </ArgonTypography>

                                </ArgonBox>

                            </ArgonBox>
                            <Grid item xs={12} sm={12} p={3}>
                                <ArgonTypography
                                    sx={{
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: '137%',
                                        letterSpacing: '-0.8px',
                                    }}
                                    mb={2}
                                >
                                    Tinnitus Info
                                </ArgonTypography>

                                <Card
                                    sx={{
                                        padding: '30px',
                                        gap: '10px',
                                        borderRadius: '20px',
                                        background: '#F4F2F2',
                                    }}
                                >
                                    <ArgonBox
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '16px',
                                        }}
                                    >
                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Tinnitus Type
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {patientData?.tinnitus_type}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Ears
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {patientData?.ears}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Start date of tinnitus
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {formatDate(patientData?.tinnitus_start_date)}
                                            </ArgonTypography>
                                        </ArgonBox>


                                    </ArgonBox>


                                </Card>
                                <ArgonTypography
                                    sx={{
                                        fontSize: '16px',
                                        fontStyle: 'normal',
                                        fontWeight: 600,
                                        lineHeight: '137%',
                                        letterSpacing: '-0.8px',
                                    }}
                                    my={2}
                                >
                                    Basic Info
                                </ArgonTypography>

                                <Card
                                    sx={{
                                        padding: '30px',
                                        gap: '10px',
                                        borderRadius: '20px',
                                        background: '#F4F2F2',
                                    }}
                                >
                                    <ArgonBox
                                        sx={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'flex-start',
                                            gap: '16px',
                                        }}
                                    >
                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Phone
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {patientData?.phone_number}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Email
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldEmailValueStyle}
                                                my={1}
                                            >
                                                {patientData?.email}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Date of Birth
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {formatDate(patientData?.dob)}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Address
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {patientData?.address1}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                City
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {patientData?.city}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Postal Code
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {patientData?.postal_code}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                State/Province
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {patientData?.state}
                                            </ArgonTypography>
                                        </ArgonBox>

                                        <ArgonBox>
                                            <ArgonTypography
                                                sx={fieldNameStyle}
                                            >
                                                Country
                                            </ArgonTypography>
                                            <ArgonTypography
                                                sx={fieldValueStyle}
                                                my={1}
                                            >
                                                {patientData?.country}
                                            </ArgonTypography>
                                        </ArgonBox>

                                    </ArgonBox>


                                </Card>

                            </Grid>

                        </Card>

                    </Grid>
                    <Grid item xs={12} sm={8} >
                        <Card>
                            <ArgonBox sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                                py={5}
                                px={4}
                                className="parent"
                            >
                                <ArgonTypography
                                    sx={{
                                        color: 'var(--Default, #172B4D)',
                                        fontFamily: 'Open Sans',
                                        fontSize: '25px',
                                        fontStyle: 'normal',
                                        fontWeight: 700,
                                        lineHeight: '137%', // Converted from "137%"
                                        letterSpacing: '-0.8px',
                                        display: "flex",
                                        alignItems: "center"
                                    }}
                                    className="header"
                                >
                                    Patient Diagnosis
                                </ArgonTypography>
                                <ArgonBox className="button-size">
                                    <ArgonButton
                                        variant="outlined"
                                        color="info"
                                        sx={{
                                            // padding: "10px 20px",
                                            borderRadius: "4px",
                                            border: "1px solid #0A9CC4",
                                            marginRight: "16px",
                                            marginLeft: "16px",
                                            width: "fit-content",
                                        }}
                                        onClick={handleReset}
                                    >
                                        Reset Diagnosis
                                    </ArgonButton>
                                    <ArgonButton
                                        variant="contained"
                                        color="info"
                                        sx={{
                                            display: 'inline-flex',
                                            padding: '10px 20px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            gap: '8px',
                                            borderRadius: '4px',
                                        }}
                                        onClick={() => navigate(`/${userRole}/patients/${patientData?.id}/frequency-test`)}
                                    >
                                        Set Tinnitus Frequency
                                    </ArgonButton>

                                </ArgonBox>

                            </ArgonBox>
                            <Grid item xs={12} sm={12} p={3}>
                                {
                                    patientData?.sessions.map((session, index) => (
                                        <Card
                                            key={index}
                                            sx={{
                                                padding: '30px',
                                                gap: '10px',
                                                borderRadius: '20px',
                                                background: '#F4F2F2',
                                                marginBottom: "25px",
                                            }}
                                        >
                                            <ArgonBox
                                                sx={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    alignItems: 'flex-start',
                                                    gap: '16px',
                                                }}
                                            >
                                                <ArgonTypography
                                                    sx={{
                                                        color: '#172B4D',
                                                        fontSize: '14px',
                                                        fontStyle: 'normal',
                                                        fontWeight: 700,
                                                        lineHeight: '150%',

                                                    }}
                                                >
                                                    {formatTimestamp(session.created_at)}
                                                </ArgonTypography>
                                                <ArgonBox sx={{ display: "flex", flexDirection: "row" }}>
                                                    <ArgonTypography sx={fieldNameStyle}>
                                                        Frequency:
                                                        <span className="frequency-value-session" >
                                                            {frequencyFormat(session.frequency)}
                                                        </span>
                                                    </ArgonTypography>

                                                </ArgonBox>

                                                <ArgonBox>
                                                    <ArgonTypography sx={fieldNameStyle}>
                                                        Note
                                                    </ArgonTypography>
                                                    <ArgonTypography sx={fieldValueStyle} my={1}>
                                                        {session.note}
                                                    </ArgonTypography>
                                                </ArgonBox>
                                            </ArgonBox>
                                        </Card>
                                    ))
                                }

                            </Grid>




                        </Card>

                    </Grid>
                </Grid>
                <ResetConfirmModal
                    open={showResetConfirmation}
                    onClose={() => setShowResetConfirmation(false)}
                    onConfirm={handleResetDiagnosis}
                />

            </ArgonBox>

        </DashboardLayout >
    )
}

export default ViewPatient