import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { Breadcrumbs as MuiBreadcrumbs } from "@mui/material";
import Icon from "@mui/material/Icon";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function Breadcrumbs({ icon, title, route, light }) {
    // If route is an array, remove the last two elements, otherwise initialize an empty array
    const routes = Array.isArray(route) ? route.slice(0, -2) : [];
    function format(title) {
        return title.replace(/[_-]/g, " ");
    }

    return (
        <ArgonBox mr={{ xs: 0, xl: 8 }}>
            <MuiBreadcrumbs
                sx={{
                    "& .MuiBreadcrumbs-separator": {
                        color: ({ palette: { white, grey } }) =>
                            light ? white.main : grey[600],
                    },
                }}
            >
                <Link to="/">
                    <ArgonTypography
                        component="span"
                        variant="body2"
                        color={light ? "white" : "dark"}
                        opacity={light ? 0.8 : 0.5}
                        sx={{ lineHeight: 0 }}
                    >
                        <Icon>{icon}</Icon>
                    </ArgonTypography>
                </Link>
                {/* Generate breadcrumbs links */}
                {routes.map((el, index) => (
                    <Link
                        to={`/${routes.slice(0, index + 1).join('/')}`}
                        key={el}
                    >
                        <ArgonTypography
                            component="span"
                            variant="button"
                            fontWeight="regular"
                            textTransform="capitalize"
                            color={light ? "white" : "dark"}
                            opacity={light ? 0.8 : 0.5}
                            sx={{ lineHeight: 0 }}
                        >
                            {format(el)}
                        </ArgonTypography>
                    </Link>
                ))}
                {/* Render the last breadcrumb without Link */}
                <ArgonTypography
                    component="span"
                    variant="button"
                    fontWeight="regular"
                    textTransform="capitalize"
                    color={light ? "white" : "dark"}
                    opacity={light ? 0.8 : 0.5}
                    sx={{ lineHeight: 0 }}
                >
                    {format(title)}
                </ArgonTypography>
            </MuiBreadcrumbs>
            <ArgonTypography
                fontWeight="bold"
                textTransform="capitalize"
                variant="h6"
                color={light ? "white" : "dark"}
                noWrap
            >
                {format(title)}
            </ArgonTypography>
        </ArgonBox>
    );
}

Breadcrumbs.defaultProps = {
    light: false,
};

Breadcrumbs.propTypes = {
    icon: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
    route: PropTypes.oneOfType([PropTypes.string, PropTypes.array]).isRequired,
    light: PropTypes.bool,
};

export default Breadcrumbs;
