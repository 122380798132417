import ArgonButton from "components/ArgonButton";
import PropTypes from "prop-types";
import ArgonBox from "components/ArgonBox";
import Card from "@mui/material/Card";

import planCardIcon from "../../../../assets/images/icons/blikol/plan-card-icon.svg";
import ArgonTypography from "components/ArgonTypography";
import { useState } from "react";
import PaymentModal from "../PaymentModal";

function PlanCard({ plan }) {
  const [paymentModalOpen, setPaymentModalOpen] = useState(false);
  const handleClose = () => {
    setPaymentModalOpen(false);
  };

  const handleOpenPaymentModal = () => {
    setPaymentModalOpen(true)
  };
  return (
    <ArgonBox>
      <Card>
        <ArgonBox
          sx={{
            display: "flex",
            justifyContent: "Center",
            alignItems: "Center",
            flexDirection: "column",
            paddingTop: "30px",
            paddingBottom: "30px",
          }}
        >
          <ArgonBox
            component="img"
            src={plan?.thumbnail_url || planCardIcon}
            alt="thumbnail"
            width="3rem"
          />
          <ArgonTypography mt={3} fontWeight="bold" opacity={0.6}>
            {plan?.name}
          </ArgonTypography>
          <ArgonTypography mt={6} fontWeight="bold" fontSize="1.8rem">
            Buy
          </ArgonTypography>
          <ArgonTypography mt={1} fontSize="1.1rem">
            {plan.credits} Credits Get {plan.free_credits} For Free
          </ArgonTypography>
          <ArgonBox width="100%" display="flex" flexDirection="column" alignItems="center">
            <ArgonTypography mt={10} fontWeight="medium">
              €{plan.price}
            </ArgonTypography>
            <ArgonBox mt="0.8rem" width="75%">
              <ArgonButton color="info" fullWidth onClick={handleOpenPaymentModal}>
                Buy
              </ArgonButton>
            </ArgonBox>
          </ArgonBox>
          {paymentModalOpen && (
            <PaymentModal
              plan={plan}
              setPaymentModalOpen={setPaymentModalOpen}
              paymentModalOpen={paymentModalOpen}
            />
          )}
        </ArgonBox>
      </Card>
    </ArgonBox>
  );
}

PlanCard.defaultProps = {
  plan: "",
};

PlanCard.propTypes = {
  plan: PropTypes.object,
};

export default PlanCard;
