import PropTypes from "prop-types";

import React, { useState, useEffect, useRef } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

import ArgonTypography from "components/ArgonTypography";

import "./FrequencySlider.css";
import { FREQUENCY_TEST } from "constants";
import { FREQUENCY_BAR_COLORS } from "constants";

const FrequencySlider = ({
  sliderIdentifier,
  title,
  backgroundColor,
  frequency,
  setFrequency,
  setIsTunePlaying,
  onAfterChange,
  isTunePlaying,
  id,
  minFrequency,
  maxFrequency,
  firstTestResult,
  backgroundGradient,
}) => {
  const [isThumbDragged, setIsThumbDragged] = useState(false);

  const sliderCircleStyles = {
    borderColor: "white",
    height: 20,
    width: 20,
    marginTop: 25,
    backgroundColor: "#0A9CC4",
    filter: "drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))",
    transition: !isThumbDragged ? "left 0.4s, right 0.7s" : "none",
  };

  const handleAfterChange = (frequency) => {
    setIsThumbDragged(false);
    onAfterChange(frequency);
  };

  const handleFrequencyChange = (value) => {
    setFrequency(value);
  };

  const handleThumDragged = () => {
    setIsThumbDragged(true);
  };

  const sliderLabel = (value) => {
    return {
      style: {
        paddingTop: "2.5rem",
        fontSize: "1rem",
      },
      label: (
        <div>
          <div className="vertical-bar"></div>
          <div className="marks-label">{value}</div>
        </div>
      ),
    };
  };

  const createVerticalSubBar = () => {
    return {
      style: {
        paddingTop: "2.5rem",
        fontSize: "1rem",
      },
      label: (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div className="vertical-sub-bar"></div> <div>&nbsp;</div>
        </div>
      ),
    };
  };

  const createMarks = (min, max, totalMarks) => {
    let difference = Math.ceil((max - min) / totalMarks);
    const marks = {};

    let step = min;
    for (let i = 0; i < totalMarks + 1; i++) {
      if (step > max) {
      }
      marks[step] = step > max ? sliderLabel(max) : sliderLabel(step);
      const subStep = Math.floor(step + difference / 2);
      if (subStep < max) {
        marks[subStep] = createVerticalSubBar();
      }
      step = step + difference;
    }
    return marks;
  };

  const defaultMarks = {
    500: sliderLabel(500),
    1000: createVerticalSubBar(),
    2000: sliderLabel(2000),
    3000: createVerticalSubBar(),
    4000: sliderLabel(4000),
    5000: createVerticalSubBar(),
    6000: sliderLabel(6000),
    7000: createVerticalSubBar(),
    8000: sliderLabel(8000),
    9000: createVerticalSubBar(),
    10000: sliderLabel(10000),
    11000: createVerticalSubBar(),
    12000: sliderLabel(12000),
  };

  const initialSearchMarks = {
    500: sliderLabel(500),
    750: createVerticalSubBar(),
    1000: sliderLabel(1000),
    1500: createVerticalSubBar(),
    2000: sliderLabel(2000),
    3000: createVerticalSubBar(),
    4000: sliderLabel(4000),
    7000: createVerticalSubBar(),
    6000: sliderLabel(6000),
    8000: sliderLabel(8000),
    9000: createVerticalSubBar(),
    10000: sliderLabel(10000),
    11000: createVerticalSubBar(),
    12000: sliderLabel(12000),
  };

  const secondTestMarks = {
    500: sliderLabel(500),
    750: createVerticalSubBar(),
    1000: sliderLabel(),
    1500: createVerticalSubBar(),
    2000: sliderLabel(),
    3000: createVerticalSubBar(),
    4000: sliderLabel(),
    7000: createVerticalSubBar(),
    6000: sliderLabel(6000),
    8000: sliderLabel(),
    9000: createVerticalSubBar(),
    10000: sliderLabel(),
    11000: createVerticalSubBar(),
    12000: sliderLabel(12000),
  };

  return (
    <>
      {firstTestResult && (
        <ArgonTypography textAlign="center" fontWeight="bold">
          First Test Result: {firstTestResult}
        </ArgonTypography>
      )}
      <div className="frequency-slider-container" id={id}>
        <div className="slider-header-title">
          <ArgonTypography
            sx={{ fontSize: "0.8rem", fontWeight: "600", textTransform: "uppercase" }}
          >
            {title}
          </ArgonTypography>
          <ArgonTypography sx={{ fontSize: "0.9rem", fontWeight: "700", textAlign: "center" }}>
            {frequency}
          </ArgonTypography>
        </div>

        <Slider
          min={minFrequency}
          max={maxFrequency}
          marks={
            sliderIdentifier === FREQUENCY_TEST.initial
              ? initialSearchMarks
              : sliderIdentifier === FREQUENCY_TEST.secondTest
              ? initialSearchMarks
              : createMarks(minFrequency, maxFrequency, 4)
          }
          included={false}
          styles={{
            handle: sliderCircleStyles,
            rail: {
              backgroundColor: backgroundColor ? backgroundColor : FREQUENCY_BAR_COLORS.default,
              ...(backgroundGradient && {
                background: backgroundGradient,
              }),
              height: 110,
              cursor: "pointer",
            },
          }}
          step={1}
          value={frequency}
          onChange={handleFrequencyChange}
          onChangeComplete={handleAfterChange}
          dots={false}
          disabled={isTunePlaying}
          onFocus={handleThumDragged}
        />
      </div>
    </>
  );
};

export default FrequencySlider;

FrequencySlider.defaultProps = {
  backgroundColor: "lightblue",
  title: "",
  frequency: 0,
  id: "",
  sliderIdentifier: "",
};

FrequencySlider.propTypes = {
  backgroundColor: PropTypes.string,
  title: PropTypes.string,
  id: PropTypes.string,
  frequency: PropTypes.number,
  setFrequency: PropTypes.func,
  onAfterChange: PropTypes.func,
  setIsTunePlaying: PropTypes.func,
  isTunePlaying: PropTypes.bool,
  minFrequency: PropTypes.number,
  maxFrequency: PropTypes.number,
  firstTestResult: PropTypes.number,
  backgroundGradient: PropTypes.string,
  sliderIdentifier: PropTypes.string,
};
