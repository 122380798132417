import React from "react";
import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormHelperText from "@mui/material/FormHelperText";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";

//routes
import { useNavigate, useLocation } from "react-router-dom";

//validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

import axios from "axios";
//external components
import "react-country-state-city/dist/react-country-state-city.css";
import useUserService from "service-hooks/useUserService";
import "../style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import ArgonSelect from "components/ArgonSelect";


function userForm({ userId, userData, buttonText, heading, handleSubmit }) {
    const navigate = useNavigate();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const userHookService = useUserService();
    const [initialValues, setInitialValues] = useState(userData);
    const validationSchema = Yup.object({
        name: Yup.string().required("User Name is required"),
        email: Yup.string().email("Invalid email address").required("User Email is required"),
        phone_number: Yup.string()
            .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Invalid phone number")
            .required("Phone number is required"),
        role: Yup.string().required("Role is required"),
    });

    const getDefaultValue = (fieldName) => {
        if (userId) {
            return { id: userId, name: initialValues[fieldName] };
        }
    };

    const formatString = (inputString) => {
        const wordsArray = inputString.split('_');
        const formattedWords = wordsArray.map(word => word.charAt(0).toUpperCase() + word.slice(1));
        const formattedString = formattedWords.join(' ');

        return formattedString;
    };

    return (
        <>
            <ArgonBox px={3} pt={3}>
                <ArgonTypography
                    sx={{
                        color: "var(--Default, #172B4D)",
                        fontSize: "25px",
                        fontStyle: "normal",
                        fontWeight: "700",
                        lineHeight: "137%",
                        letterSpacing: "-0.8px",
                    }}
                >
                    {heading}
                </ArgonTypography>
            </ArgonBox>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values) => handleSubmit(values)}
            >
                {(props) => (
                    <Form>
                        <Card
                            id="basic-info"
                            sx={{
                                overflow: "visible",
                                borderRadius: "20px",
                                margin: "20px 20px 40px 20px",
                                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
                            }}
                        >
                            <ArgonBox p={3}>
                                <ArgonTypography variant="h5">Basic Info</ArgonTypography>
                            </ArgonBox>
                            <ArgonBox pb={3} px={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={6}>
                                        <FormField
                                            label="user name"
                                            placeholder="Alec Benjamin"
                                            value={props.values.name}
                                            name="name"
                                            error={props.errors.name && props.touched?.name}
                                            onChange={props.handleChange}
                                            requiredField={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormField
                                            label="user email"
                                            placeholder="example@email.com"
                                            name="email"
                                            value={props.values.email}
                                            error={props.errors.email && props.touched?.email}
                                            onChange={props.handleChange}
                                            requiredField={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <FormField
                                            label="user phone"
                                            placeholder="+92 321 3701015"
                                            name="phone_number"
                                            value={props.values.phone_number}
                                            error={props.errors.phone_number && props.touched?.phone_number}
                                            onChange={props.handleChange}
                                            requiredField={true}
                                        />
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <ArgonTypography
                                            component="label"
                                            variant="caption"
                                            fontWeight="bold"
                                            textTransform="capitalize"
                                            mb={1}
                                            ml={0.5}
                                        >
                                            Role  <span style={{ color: "red" }}>*</span>
                                        </ArgonTypography>
                                        <ArgonSelect
                                            placeholder="e.g Admin"
                                            options={[
                                                { value: "secretary", label: "Secretary" },
                                                { value: "admin", label: "Admin" },
                                                { value: "super_admin", label: "Super Admin" },
                                            ]}
                                            onChange={(selectedOption) => {
                                                props.setFieldValue("role", selectedOption ? selectedOption.value : null);
                                            }}
                                            defaultValue={props.values.role ? { label: formatString(props.values.role), value: props.values.role } : null}
                                            error={props.errors.role && props.touched?.role}
                                        />
                                        {props.errors.role && props.touched?.role && (
                                            <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                                                {props.errors.role}
                                            </FormHelperText>
                                        )}
                                    </Grid>
                                </Grid>
                            </ArgonBox>
                        </Card>
                        <ArgonBox p={3}>
                            <ArgonButton
                                type="submit"
                                fullWidth
                                variant="contained"
                                color={"info"}
                                sx={{
                                    display: "flex",
                                    width: "124px",
                                    padding: " 10px 20px",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    gap: "8px",
                                }}
                            >
                                {buttonText}
                            </ArgonButton>
                        </ArgonBox>
                    </Form>
                )}
            </Formik>
        </>
    );
}

userForm.defaultProps = {
    userId: "",
    userData: {
        name: "",
        email: "",
        phone_number: "",
        role: "",
    },

    buttonText: "Add",
    heading: "Add New User",
    handleSubmit: "",

};

export default userForm;
