import React from "react";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Spinner from "../../components/Spinner";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import { useState, useEffect, useMemo } from "react";
import useStatisticsService from "service-hooks/clinic/useStatisticsService";
import DetailedStatisticsCard from "blikol-layouts/components/DetailedStatisticsCard";
import PatientsIcon from "../../../assets/images/icons/clinic/patients.svg";
import SessionsIcon from "../../../assets/images/icons/clinic/sessions.svg";
import VerticalBarChart from "examples/Charts/BarCharts/VerticalBarChart";
import Card from "@mui/material/Card";
import "./style.css";
import ArgonSelect from "components/ArgonSelect";
import { DateRangePicker, Stack } from "rsuite";
import card from "assets/theme-dark/components/card";

function Statistics() {
  const [loading, setLoading] = useState(false);
  const statisticsHookService = useStatisticsService();
  const [graphStatistics, setGraphStatistics] = useState();
  const [selectedOption, setSelectedOption] = useState("month");
  const [cardStatistics, setCardStatistics] = useState();
  const [dateRange, setDateRange] = useState("");
  const imageIcon1 = {
    color: "primary", // specify the color if needed
    component: <img src={PatientsIcon} alt="Patients" />,
  };
  const imageIcon2 = {
    color: "primary", // specify the color if needed
    component: <img src={SessionsIcon} alt="Sessions" />,
  };
  const optionsArray = { month: "Monthly", year: "Yearly" };
  // Add more options as needed

  useEffect(() => {
    statisticsHookService
      .graphStatistics(selectedOption)
      .then((result) => {
        if (result) {
          setGraphStatistics(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [selectedOption]);

  useEffect(() => {
    statisticsHookService
      .cardStatistics(dateRange)
      .then((result) => {
        if (result) {
          setCardStatistics(result);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [dateRange]);

  const graphHash = graphStatistics?.graph_hash;

  const chartData = {
    labels: graphHash ? Object.keys(graphHash) : [],
    datasets: [
      {
        chartType: "thin-bar",
        label: "Patients",
        color: "dark",
        data: graphHash ? Object.values(graphHash) : [],
      },
    ],
  };

  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Statistics"} />
      <ArgonBox px={3} pt={3}>
        <ArgonBox sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <ArgonTypography
            mb={3}
            mt={4}
            sx={{
              color: "var(--Default, #172B4D)",
              fontSize: "25px",
              fontStyle: "normal",
              fontWeight: "700",
              lineHeight: "137%",
              letterSpacing: "-0.8px",
            }}
          >
            Statistics
          </ArgonTypography>
          <ArgonBox sx={{ alignItems: "center" }}>
            <DateRangePicker
              placeholder="Apply date filter"
              showOneCalendar
              style={{ width: 250 }}
              size="lg"
              format="dd.MM.yyyy"
              onShortcutClick={(shortcut, event) => {
                setDateRange(shortcut.value);
              }}
              onOk={(shortcut) => {
                setDateRange(shortcut);
              }}
              onClean={(shortcut) => {
                setDateRange("");
              }}
              ranges={[]}
            />
          </ArgonBox>
        </ArgonBox>
        {loading && <Spinner />}
        {!loading && (
          <ArgonBox py={3}>
            <Grid container spacing={3} mb={3}>
              <Grid item xs={12} md={6} lg={6}>
                <DetailedStatisticsCard
                  title="Total Patients"
                  count={cardStatistics?.total_patients}
                  icon={imageIcon1}
                />
              </Grid>

              <Grid item xs={12} md={6} lg={6}>
                <DetailedStatisticsCard
                  title="Total Sessions"
                  count={cardStatistics?.total_sessions}
                  icon={imageIcon2}
                />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Card>
                <ArgonBox
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <ArgonBox
                    p={2}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <ArgonTypography variant="h6" mb={1} sx={{ color: "var(--Default, #172B4D)" }}>
                      Overview
                    </ArgonTypography>
                    <ArgonTypography variant="h6" sx={{ color: "var(--Default, #172B4D)" }}>
                      Total Patients:{" "}
                      <span className="patients-text">
                        {graphStatistics?.filter_patients_count}
                      </span>
                    </ArgonTypography>
                  </ArgonBox>
                  <ArgonBox p={2}>
                    <ArgonSelect
                      options={[
                        { label: "Monthly", value: "month" },
                        { label: "Weekly", value: "week" },
                      ]}
                      placeholder="Filter"
                      defaultValue={
                        selectedOption
                          ? { label: optionsArray[selectedOption], value: selectedOption }
                          : null
                      }
                      onChange={({ value }) => setSelectedOption(value)}
                    />
                  </ArgonBox>
                </ArgonBox>
                <VerticalBarChart height="25rem" chart={chartData} />
              </Card>
            </Grid>
          </ArgonBox>
        )}
      </ArgonBox>
    </DashboardLayout>
  );
}

export default Statistics;
