import React from "react";
import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormHelperText from "@mui/material/FormHelperText";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";

//routes
import { useNavigate, useLocation } from "react-router-dom";

//validation
import { Formik, Form } from "formik";
import * as Yup from "yup";

//external components
import "react-country-state-city/dist/react-country-state-city.css";
import useClinicService from "service-hooks/useClinicService";
import "../style.css";
import { useSelector } from "react-redux";
import {
  GetCountries,
  GetState,
  GetCity, //async functions
} from "react-country-state-city";
import ArgonSelect from "components/ArgonSelect";
import button from "assets/theme/components/button";

function clinicForm({ clinicId, clinicData, buttonText, heading, justifyContent, handleSubmit, buttonDisabled }) {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.user?.role);
  const clinicHookService = useClinicService();
  const [countryid, setCountryid] = useState(0);
  const [stateId, setstateId] = useState(0);
  const [cityId, setcityId] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [initialValues, setInitialValues] = useState(clinicData);

  const validationSchema = Yup.object({
    name: Yup.string().required("Clinic Name is required"),
    email: Yup.string().email("Invalid email address").required("Clinic Email is required"),
    phone_number: Yup.string()
      .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Invalid phone number")
      .required("Phone number is required"),
    address1: Yup.string().required("Address1 is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    note: Yup.string(),
    contact_person_info: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      phone_number: Yup.string()
        .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Invalid phone number")
        .required("Phone number is required"),
    }),
    clinic_notes_attributes: Yup.array().of(
      Yup.object().shape({
        note: Yup.string()
      })
    ),
    postal_code: Yup.string().required("Postal code is required"),
  });


  const getDefaultValue = (fieldName) => {
    if (clinicId) {
      return { id: clinicId, name: initialValues[fieldName] };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetCountries();
        setCountriesList(response);
      } catch (e) {
        console.log("there seems to be an error", e);
      }
    };
    fetchData();
  }, []);

  const countryChange = async (value) => {
    try {
      setCountryid(value);
      const response = await GetState(value);
      response.length !== 0 ? setStateList(response) : setStateList(null);
      setCityList(null);
      setcityId("");
      setstateId("");
    } catch (e) {
      console.log("there seems to be an error", e);
    }
  };

  const stateChange = async (value) => {
    try {
      setstateId(value);
      const response = await GetCity(countryid, value);
      response.length !== 0 ? setCityList(response) : setCityList(null);
    } catch (e) {
      console.log("there seems to be an error", e);
    }
  };

  return (
    <>
      <ArgonBox px={3} pt={3}>
        <ArgonTypography
          sx={{
            color: "var(--Default, #172B4D)",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
          }}
        >
          {heading}
        </ArgonTypography>
      </ArgonBox>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(props) => (
          <Form>
            <Card
              id="clinic-info"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Clinic Info</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="clinic name"
                      placeholder="Alec Benjamin"
                      value={props.values.name}
                      name="name"
                      error={props.errors.name && props.touched?.name}
                      onChange={props.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="clinic email"
                      placeholder="example@email.com"
                      name="email"
                      value={props.values.email}
                      error={props.errors.email && props.touched?.email}
                      onChange={props.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="phone number"
                      placeholder="+92 321 3701015"
                      name="phone_number"
                      value={props.values.phone_number}
                      error={props.errors.phone_number && props.touched?.phone_number}
                      onChange={props.handleChange}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="contact-personal-info"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Contact Person Info</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="name"
                      name="contact_person_info.name"
                      placeholder="Alec Benjamin"
                      value={props.values?.contact_person_info?.name}
                      error={
                        props.errors.contact_person_info?.name &&
                        props.touched?.contact_person_info?.name
                      }
                      onChange={props.handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="phone number"
                      placeholder="+92 321 3701015"
                      value={props.values?.contact_person_info?.phone_number}
                      name="contact_person_info.phone_number"
                      error={
                        props.errors.contact_person_info?.phone_number &&
                        props.touched.contact_person_info?.phone_number
                      }
                      onChange={props.handleChange}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="clinic address"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Clinic Address</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      label="address 1"
                      placeholder="eg. Street 120"
                      value={props.values.address1}
                      name="address1"
                      error={props.errors.address1 && props.touched?.address1}
                      onChange={props.handleChange}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} pt={1.5} mb={1.5}>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        Country <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonSelect
                      options={countriesList.map((option) => ({
                        label: option.name,
                        value: option.id,
                      }))}
                      placeholder="Select Country"
                      defaultValue={
                        props.values.country
                          ? { label: props.values.country, value: props.values.country }
                          : null
                      }
                      onChange={(selectedOption) => {
                        props.setFieldValue(
                          "country",
                          selectedOption ? selectedOption.label : null
                        );
                        props.setFieldValue("state", null);
                        props.setFieldValue("city", null);
                        setStateList(null);
                        countryChange(selectedOption.value);
                      }}
                      error={props.errors.country && props.touched?.country}
                    />
                    {props.errors.country && props.touched?.country && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.country}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        State/Province <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    {stateList !== null ? (
                      <ArgonSelect
                        options={stateList.map((option) => ({
                          label: option.name,
                          value: option.id,
                        }))}
                        defaultValue={
                          props.values.state
                            ? { label: props.values.state, value: props.values.state }
                            : null
                        }
                        onChange={async (selectedOption) => {
                          props.setFieldValue(
                            "state",
                            selectedOption ? selectedOption.label : null
                          );
                          stateChange(selectedOption.value);
                          props.setFieldValue("city", null);
                          setCityList(null);
                        }}
                        error={props.errors.state && props.touched?.state}
                        placeholder="Select State"
                      />
                    ) : (
                      <ArgonInput
                        defaultValue={props.values.state}
                        onChange={(e) => {
                          props.setFieldValue("state", e.target.value);
                          props.setFieldValue("city", null);
                        }}
                        placeholder="e.g Punjab"
                        error={props.errors.state && props.touched?.state}
                      ></ArgonInput>
                    )}
                    {props.errors.state && props.touched?.state && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.state}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        mb={1}
                      >
                        City <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    {cityList !== null ? (
                      <ArgonSelect
                        options={cityList.map((option) => ({
                          label: option.name,
                          value: option.id,
                        }))}
                        onChange={(selectedOption) => {
                          props.setFieldValue("city", selectedOption ? selectedOption.label : null);
                        }}
                        error={props.errors.city && props.touched?.city}
                        defaultValue={
                          props.values.city
                            ? { label: props.values.city, value: props.values.city }
                            : null
                        }
                        placeholder="Select City"
                      />
                    ) : (
                      <ArgonInput
                        placeholder="e.g Lahore"
                        error={props.errors.city && props.touched?.city}
                        onChange={(e) => {
                          props.setFieldValue("city", e.target.value);
                        }}
                        defaultValue={props.values.city}
                      ></ArgonInput>
                    )}
                    {props.errors.city && props.touched?.city && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.city}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormField
                      label="post code"
                      placeholder="eg. 54770"
                      value={props.values.postal_code}
                      name="postal_code"
                      error={props.errors.postal_code && props.touched?.postal_code}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="note"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
                marginTop: "150p",
              }}
            >
              <ArgonBox px={3} pt={3} pb={1.5}>
                <ArgonTypography variant="h5">Note</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3} mb={1.5}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <ArgonBox>
                      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <ArgonTypography
                          sx={{
                            color: "var(--Default, #172B4D)",

                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "150%",
                          }}
                        >
                          Add Note
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonInput
                        placeholder="Type here..."
                        name={(userRole === "admin" || userRole === "super_admin") ? "clinic_notes_attributes[0].note" : "note"}
                        multiline
                        rows={5}
                        value={userRole === "admin" || userRole === "super_admin" ? props.values.clinic_notes_attributes[0].note : props.values.note}
                        onChange={props.handleChange}
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <ArgonBox
              p={3}
              sx={{
                display: "flex",
                justifyContent: justifyContent,
              }}
            >
              <ArgonButton
                type="submit"
                fullWidth
                variant="contained"
                color={"info"}
                sx={{
                  display: "flex",
                  width: "124px",
                  padding: " 10px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
                disabled={buttonDisabled}
              >
                {buttonText}
              </ArgonButton>
            </ArgonBox>
          </Form>
        )}
      </Formik>
    </>
  );
}

clinicForm.defaultProps = {
  clinicId: "",
  clinicData: {
    name: "",
    email: "",
    phone_number: "",
    address1: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    note: "",
    clinic_notes_attributes: [{
      note: ""
    }],
    contact_person_info: {
      name: "",
      phone_number: "",
    },
  },
  buttonText: "",
  heading: "",
  justifyContent: "start",
  handleSubmit: "",
  buttonDisabled: false
};

export default clinicForm;
