import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PersonIcon from "assets/images/icons/patients/person-icon.svg"
import usePatientsService from "service-hooks/usePatientsService";
import Spinner from "../../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ArgonButton from "components/ArgonButton";
import "../../style.css"
import PersonalInfo from "./components/PersonalInfo";
import Header from "../Header"
import PatientDiagnosis from "./components/PatientDiagnosis";
import SessionHistory from "./components/SessionHistory";

function ViewPatient() {
    const navigate = useNavigate();
    const patientHookService = usePatientsService();
    const [loading, setLoading] = useState(true);
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [patientId, setPatientId] = useState(useParams().id);
    const [currentFrequency, setCurrentFrequency] = useState()
    const [tabVal, setTabVal] = useState("Patient Diagnosis")

    useEffect(() => {
        patientHookService
            .patient(patientId)
            .then((result) => {
                if (result) {
                    const patient = result.data;
                    setLoading(false);
                    setPatientId(patient?.id);
                    setCurrentFrequency(patient?.current_frequency)
                } else {
                    toast.error("No Patient with this id");
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    }, []);

    const handleTabChange = (selectedTab) => {
        setTabVal(selectedTab);
    };

    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        textTransform: "capitalize",

    }

    const fieldEmailValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    const tabStyle = {
        fontSize: "14px !important",
        borderRadius: "4px",
        padding: "0px 8px",
        height: "35px"
    }

    function frequencyFormat(frequency) {
        if (frequency) {
            return frequency.toString() + 'Hz';
        }
    }

    return (
        <DashboardLayout colorBgHeight={"110px"}>
            <DashboardNavbar customRoutePath={"Patients / Patient Detail"} />
            <ArgonBox px={3} pt={3}>
                <ArgonBox
                    sx={{ display: "flex", justifyContent: "space-between" }}
                >
                    <ArgonTypography
                        mb={3}
                        mt={4}
                        sx={{
                            color: "var(--Default, #172B4D)",
                            fontSize: "25px",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "137%",
                            letterSpacing: "-0.8px",
                        }}
                    >
                        Patient Details
                    </ArgonTypography>
                    <ArgonBox mb={3} mt={4}>
                        <Card>
                            <Header
                                onTabChange={handleTabChange}
                                numTabs={2}
                                tabNames={["Patient Diagnosis", "Session History"]}
                                customStyle={tabStyle}

                            />
                        </Card>
                    </ArgonBox>

                    <ArgonTypography
                        mb={3}
                        mt={4}
                        sx={{
                            color: 'var(--Label, #8392AB)',
                            fontSize: '20px',
                            fontStyle: 'normal',
                            fontWeight: 600,
                            lineHeight: 'normal',
                        }}
                    >
                        Current Frequency:
                        <span className="current-frequency-value">
                            {" "}{frequencyFormat(currentFrequency)}
                        </span>
                    </ArgonTypography>
                </ArgonBox>
                <Grid container spacing={3}>

                    <Grid item xs={12} sm={4} >
                        <PersonalInfo />
                    </Grid>
                    <Grid item xs={12} sm={8} >
                        {tabVal === "Patient Diagnosis" ? (<PatientDiagnosis />) : <SessionHistory />}
                    </Grid>
                </Grid>

            </ArgonBox>

        </DashboardLayout >
    )
}

export default ViewPatient