import "./style.css";
import PropTypes from "prop-types";

import PlanCard from "../components/PlanCard";
import { useEffect, useState } from "react";
import useCreditPackageService from "service-hooks/clinic/useCreditPackageService";
import Spinner from "../../components/Spinner";
import DashboardLayout from "blikol-layouts/components/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

function Plans() {
  const [plans, setPlans] = useState([]);
  const [loading, setLoading] = useState(true);

  const creditPackageHookService = useCreditPackageService();
  useEffect(() => {
    creditPackageHookService
      .packages()
      .then((result) => {
        setPlans(result);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <DashboardLayout colorBgHeight={"110px"}>
      <DashboardNavbar customRoutePath={"Credit Plans"} />
      <ArgonBox px={3} pt={3}>
        <ArgonTypography
          sx={{
            color: "var(--Default, #172B4D)",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
          }}
        >
          Purchase Credits
        </ArgonTypography>
      </ArgonBox>
      <Grid mt={3} container spacing={3}>
        {loading && (
          <ArgonBox m={"auto"}>
            <Spinner />
          </ArgonBox>
        )}
        {!loading &&
          plans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan.id}>
              <PlanCard plan={plan} />
            </Grid>
          ))}
        {!loading && plans.length == 0 && (
          <ArgonBox m={"auto"} color={"red"}>
            No plans added by admin right now.
          </ArgonBox>
        )}
      </Grid>
    </DashboardLayout>
  );
}

export default Plans;
