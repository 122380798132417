import { useDispatch } from "react-redux";
import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const usePatientsService = () => {
    const dispatch = useDispatch();
    const axiosRequest = useBaseService().axiosRequest();

    const patients = async (currentPage, itemsPerPage, gender = "", query = "", tinnitus_type = "", status = "", country = "", city = "", clinic) => {
        try {
            const response = await axiosRequest.get(
                `administrator_apis/patients?page=${currentPage}&status=${status}&items_per_page=${itemsPerPage}&gender=${gender}&query=${query}&tinnitus_type=${tinnitus_type}&city=${city}&country=${country}&clinic_id=${clinic}`
            );
            const patientsData = response.data.patients;
            const patientsMeta = response.data.meta;
            return { patientsData, patientsMeta };
        } catch (error) {
            toast.error(error.response.data.error);

            return false;
        }
    };

    const countries = async (status) => {
        try {
            const response = await axiosRequest.get(`administrator_apis/patients/countries?status=${status}`);
            const countries = response;
            return { countries };
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const cities = async (status) => {
        try {
            const response = await axiosRequest.get(`administrator_apis/patients/cities?status=${status}`);
            const cities = response;
            return { cities };
        } catch (error) {
            toast.error(error.response.data.error);

            return false;
        }
    };


    const updateStatus = async (status = "", id) => {
        try {
            const response = await axiosRequest.put(`administrator_apis/patients/${id}/change_status?status=${status}`);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false; // You can handle the error as needed in the calling code.
        }
    };

    const patient = async (id) => {
        try {
            const response = await axiosRequest.get(`administrator_apis/patients/${id}`);
            return response;
        } catch (error) {
            toast.error(error.response.data.error);
            return false; // You can handle the error as needed in the calling code.
        }
    };



    return {
        patients,
        countries,
        cities,
        patient,
        updateStatus,
    };
};

export default usePatientsService;
