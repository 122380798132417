import React from "react";
import { useState, useEffect } from "react";

// @mui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import FormHelperText from "@mui/material/FormHelperText";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import ArgonSelect from "components/ArgonSelect";
import ArgonDatePicker from "components/ArgonDatePicker";

// Settings page components
import FormField from "layouts/pages/users/new-user/components/FormField";

//routes
import { useNavigate, useLocation } from "react-router-dom";

//validation
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import axios from "axios";
//external components
import "react-country-state-city/dist/react-country-state-city.css";
import usePatientsService from "service-hooks/clinic/usePatientsService";
import "../../style.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector, useDispatch } from "react-redux";
import {
  GetCountries,
  GetState,
  GetCity, //async functions
} from "react-country-state-city";
import { formatDate } from "@fullcalendar/core";
import countryList from "react-select-country-list";
import { setUser } from "slices/authSlice";

function patientForm({ patientId, patientData, buttonText, heading }) {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.auth?.user?.role);
  const patientHookService = usePatientsService();
  const [countryid, setCountryid] = useState(0);
  const [stateId, setstateId] = useState(0);
  const [cityId, setcityId] = useState(0);
  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);
  const dispatch = useDispatch();
  const [temp, setTemp] = useState();
  const [initialValues, setInitialValues] = useState(patientData);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false)
  const validationSchema = Yup.object({
    name: Yup.string().required("First name is required"),
    middle_name: Yup.string(),
    last_name: Yup.string().required("Last name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone_number: Yup.string()
      .matches(/^\+(?:[0-9] ?){6,14}[0-9]$/, "Invalid phone number")
      .required("Phone number is required"),
    dob: Yup.date().required('Date of Birth is required').nullable(),
    gender: Yup.string().required("Gender is required"),
    address1: Yup.string().required("Address1 is required"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    city: Yup.string().required("City is required"),
    postal_code: Yup.string().required("Postal code is required"),
    note: Yup.string(),
    tinnitus_type: Yup.string().required("Tinnitus type is required"),
    ears: Yup.string().required("Selecting the ears field is required"),
    tinnitus_start_date: Yup.date().required('Start date is required').nullable(),
    // q_value: Yup.number()
    //   .required("Q value is required")
    //   .min(0.1, "Q value must be greater than or equal to 0.1")
    //   .max(52, "Q value must be less than or equal to 52"),
  });



  const handleSubmit = (values) => {
    if (patientId) {
      patientHookService.update(values, patientId).then((result) => {
        if (result) {
          setIsButtonDisabled(true)
          toast.success("Patient updated successfully.");
          navigate(`/${userRole}/patients`);
        }
      });
    } else {
      patientHookService
        .create(values)
        .then((result) => {
          if (result) {
            toast.success("Patient created successfully.");
            dispatch(setUser(result?.data))
            navigate(`/${userRole}/patients`);
          }
        })
        .catch((error) => {
          toast.error(error.response.data.error);
          navigate(`/${userRole}/patients`);
        });
    }
  };

  const getDefaultValue = (fieldName) => {
    if (patientId) {
      return { id: patientId, name: initialValues[fieldName] };
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await GetCountries();
        setCountriesList(response);
      } catch (e) {
        console.log("there seems to be an error", e);
      }
    };
    fetchData();
  }, []);
  useEffect(() => { }, [stateList]);

  const countryChange = async (value) => {
    try {
      setCountryid(value);
      const response = await GetState(value);

      response.length !== 0 ? setStateList(response) : setStateList(null);
      setCityList(null);
      setcityId("");
      setstateId("");
    } catch (e) {
      console.log("there seems to be an error", e);
    }
  };

  const stateChange = async (value) => {
    try {
      setstateId(value);
      const response = await GetCity(countryid, value);
      response.length !== 0 ? setCityList(response) : setCityList(null);
    } catch (e) {
      console.log("there seems to be an error", e);
    }
  };

  function formatDateFromTimestamp(timestamp) {
    const date = new Date(timestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  }

  function convertStringToDate(dateString) {
    const [year, month, day] = dateString.split("-");
    const dateObject = new Date(year, month - 1, day);

    // Check if the date is valid
    if (isNaN(dateObject.getTime())) {
      console.error("Invalid date string:", dateString);
      return null; // or throw an error, depending on your use case
    }
    return dateObject;
  }

  return (
    <>
      <ArgonBox px={3} pt={3}>
        <ArgonTypography
          sx={{
            color: "var(--Default, #172B4D)",
            fontSize: "25px",
            fontStyle: "normal",
            fontWeight: "700",
            lineHeight: "137%",
            letterSpacing: "-0.8px",

          }}
        >
          {heading}
        </ArgonTypography>
      </ArgonBox>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => handleSubmit(values)}
      >
        {(props) => (
          <Form>
            <Card
              id="basicinfo"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Basic Info</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      label="first name"
                      placeholder="Alec"
                      value={props.values.name}
                      name="name"
                      error={props.errors.name && props.touched?.name}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      label="middle name"
                      placeholder="Sandra"
                      value={props.values.middle_name}
                      name="middle_name"
                      error={props.errors.middle_name && props.touched?.middle_name}
                      onChange={props.handleChange}
                    />

                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <FormField
                      label="last name"
                      placeholder="Benjamin"
                      value={props.values.last_name}
                      name="last_name"
                      error={props.errors.last_name && props.touched?.last_name}
                      onChange={props.handleChange}
                      requiredField={true}
                    />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="phone number"
                      placeholder="+92 321 3701015"
                      name="phone_number"
                      value={props.values.phone_number}
                      error={props.errors.phone_number && props.touched?.phone_number}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <FormField
                      label="email"
                      placeholder="example@email.com"
                      name="email"
                      value={props.values.email}
                      error={props.errors.email && props.touched?.email}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                      mb={1}
                      ml={0.5}
                    >
                      Date of Birth <span style={{ color: "red" }}>*</span>
                    </ArgonTypography>
                    <ArgonInput
                      type={"date"}
                      label={"Select date of birth"}
                      required={true}
                      defaultValue={formatDateFromTimestamp(props.values.dob)}
                      onChange={(event) => {
                        const selectedDate = event.target.value;
                        props.setFieldValue("dob", selectedDate);
                      }}
                    />

                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                      mb={1}
                      ml={0.5}
                    >
                      Gender <span style={{ color: "red" }}>*</span>
                    </ArgonTypography>
                    <ArgonSelect
                      sx={{ textTransform: 'capitalize' }}
                      placeholder="Gender"
                      options={[
                        { value: "male", label: "Male" },
                        { value: "female", label: "Female" },
                      ]}
                      onChange={(selectedOption) => {
                        props.setFieldValue("gender", selectedOption ? selectedOption.value : null);
                      }}
                      error={props.errors.gender && props.touched?.gender}
                      defaultValue={
                        props.values.gender
                          ? { label: props.values.gender, value: props.values.gender }
                          : null
                      }
                    />
                    {props.errors.gender && props.touched?.gender && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.gender}
                      </FormHelperText>
                    )}
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="address"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Address</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormField
                      label="address"
                      placeholder="eg. Street 120"
                      value={props.values.address1}
                      name="address1"
                      error={props.errors.address1 && props.touched?.address1}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={4} pt={1.5} mb={1.5}>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"

                      >
                        Country  <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonSelect
                      options={countriesList.map((option) => ({ label: option.name, value: option.id }))}
                      placeholder="Select Country"
                      defaultValue={
                        props.values.country
                          ? { label: props.values.country, value: props.values.country }
                          : null
                      }
                      onChange={(selectedOption) => {
                        props.setFieldValue("country", selectedOption ? selectedOption.label : null);
                        props.setFieldValue("state", null)
                        props.setFieldValue("city", null)
                        setStateList(null)
                        countryChange(selectedOption.value)
                      }}
                      error={props.errors.country && props.touched?.country}
                    />
                    {props.errors.country && props.touched?.country && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.country}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        State/Province  <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    {
                      stateList !== null ? (<ArgonSelect
                        options={stateList.map((option) => ({ label: option.name, value: option.id }))}
                        defaultValue={
                          props.values.state
                            ? { label: props.values.state, value: props.values.state }
                            : null
                        }
                        onChange={(selectedOption) => {
                          props.setFieldValue("state", selectedOption ? selectedOption.label : null);
                          stateChange(selectedOption.value)
                          props.setFieldValue("city", null)
                          setCityList(null)

                        }}
                        error={props.errors.state && props.touched?.state}
                        placeholder="Select State"

                      />) : (
                        <ArgonInput
                          defaultValue={props.values.state}
                          onChange={(e) => {
                            props.setFieldValue("state", e.target.value);
                            props.setFieldValue("city", null);

                          }}
                          placeholder="e.g Punjab"
                          error={props.errors.state && props.touched?.state}
                        >
                        </ArgonInput>
                      )
                    }
                    {props.errors.state && props.touched?.state && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.state}
                      </FormHelperText>
                    )}
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <ArgonBox ml={0.5} display="inline-block">
                      <ArgonTypography
                        component="label"
                        variant="caption"
                        fontWeight="bold"
                        textTransform="capitalize"
                        mb={1}
                      >
                        City  <span style={{ color: "red" }}>*</span>
                      </ArgonTypography>
                    </ArgonBox>
                    {
                      cityList !== null ? (
                        <ArgonSelect
                          options={cityList.map((option) => ({ label: option.name, value: option.id }))}
                          onChange={(selectedOption) => {
                            props.setFieldValue("city", selectedOption ? selectedOption.label : null);

                          }}
                          error={props.errors.city && props.touched?.city}
                          defaultValue={
                            props.values.city
                              ? { label: props.values.city, value: props.values.city }
                              : null
                          }
                          placeholder="Select City"
                        />) : (
                        <ArgonInput
                          placeholder="e.g Lahore"
                          error={props.errors.city && props.touched?.city}
                          onChange={(e) => {
                            props.setFieldValue("city", e.target.value);
                          }}
                          defaultValue={props.values.city}
                        >
                        </ArgonInput>
                      )
                    }
                    {props.errors.city && props.touched?.city && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.city}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={3}>
                    <FormField
                      label="post code"
                      placeholder="eg. 54770"
                      value={props.values.postal_code}
                      name="postal_code"
                      error={props.errors.postal_code && props.touched?.postal_code}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <Card
              id="tinnitus"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
              }}
            >
              <ArgonBox p={3}>
                <ArgonTypography variant="h5">Tinnitus</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                      mb={1}
                      ml={0.5}
                    >
                      Start Date of Tinnitus <span style={{ color: "red" }}>*</span>
                    </ArgonTypography>
                    <ArgonInput
                      type={"date"}
                      label={"Select date of the start of tinnitus"}
                      required={true}
                      defaultValue={formatDateFromTimestamp(props.values.tinnitus_start_date)}
                      onChange={(event) => {
                        const selectedDate = event.target.value;
                        props.setFieldValue("tinnitus_start_date", selectedDate);
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                      mb={1}
                      ml={0.5}
                    >
                      Ears <span style={{ color: "red" }}>*</span>
                    </ArgonTypography>
                    <ArgonSelect
                      placeholder="e.g Left"
                      options={[
                        { value: "left", label: "Left" },
                        { value: "right", label: "Right" },
                        { value: "both", label: "Both" },
                      ]}
                      onChange={(selectedOption) => {
                        props.setFieldValue("ears", selectedOption ? selectedOption.value : null);
                      }}
                      defaultValue={
                        props.values.ears
                          ? { label: props.values.ears, value: props.values.ears }
                          : null
                      }
                      error={props.errors.ears && props.touched?.ears}
                    />
                    {props.errors.ears && props.touched?.ears && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.ears}
                      </FormHelperText>
                    )}
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <ArgonTypography
                      component="label"
                      variant="caption"
                      fontWeight="bold"
                      textTransform="capitalize"
                      mb={1}
                      ml={0.5}
                    >
                      Tinnitus Type  <span style={{ color: "red" }}>*</span>
                    </ArgonTypography>
                    <ArgonSelect
                      placeholder="e.g Tonal"
                      options={[
                        { value: "tonal", label: "Tonal" },
                        { value: "complex_noise", label: "Complex Noise" },
                        { value: "other", label: "Other" },
                      ]}
                      onChange={(selectedOption) => {
                        props.setFieldValue("tinnitus_type", selectedOption ? selectedOption.value : null);
                      }}
                      defaultValue={props.values.tinnitus_type ? { label: props.values.tinnitus_type, value: props.values.tinnitus_type } : null}
                      error={props.errors.tinnitus_type && props.touched?.tinnitus_type}
                    />
                    {props.errors.tinnitus_type && props.touched?.tinnitus_type && (
                      <FormHelperText sx={{ color: "#f5365c", fontSize: "12px", marginTop: "6px" }}>
                        {props.errors.tinnitus_type}
                      </FormHelperText>
                    )}

                  </Grid>
                  {/* <Grid item xs={12} sm={6}>
                    <FormField
                      label="q_value"
                      placeholder="e.g 10"
                      value={props.values.q_value}
                      name="q_value"
                      error={props.errors.q_value && props.touched?.q_value}
                      onChange={props.handleChange}
                      requiredField={true}
                    />
                  </Grid> */}

                </Grid>
              </ArgonBox>
            </Card>

            <Card
              id="note"
              sx={{
                overflow: "visible",
                borderRadius: "20px",
                margin: "20px 20px 40px 20px",
                boxShadow: "0px 5px 14px 0px rgba(0, 0, 0, 0.05)",
                marginTop: "150p",
              }}
            >
              <ArgonBox px={3} pt={3} pb={1.5}>
                <ArgonTypography variant="h5">Remarks</ArgonTypography>
              </ArgonBox>
              <ArgonBox pb={3} px={3} mb={1.5}>
                <Grid container spacing={3}>
                  <Grid item sm={12}>
                    <ArgonBox>
                      <ArgonBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
                        <ArgonTypography
                          sx={{
                            color: "var(--Default, #172B4D)",

                            fontSize: "12px",
                            fontStyle: "normal",
                            fontWeight: 700,
                            lineHeight: "150%",
                          }}
                        >
                          Add Remarks
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonInput
                        placeholder="Type here..."
                        name="note"
                        multiline
                        rows={5}
                        value={props.values.note}
                        onChange={props.handleChange}
                      />
                    </ArgonBox>
                  </Grid>
                </Grid>
              </ArgonBox>
            </Card>
            <ArgonBox p={3}>
              <ArgonButton
                type="submit"
                fullWidth
                variant="contained"
                color={"info"}
                sx={{
                  display: "flex",
                  width: "124px",
                  padding: " 10px 20px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                }}
                disabled={isButtonDisabled}
              >
                {buttonText}
              </ArgonButton>
            </ArgonBox>
          </Form>
        )}
      </Formik>
    </>
  );
}
patientForm.defaultProps = {
  patientId: "",
  patientData: {
    name: "",
    middle_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    gender: "",
    dob: new Date(),
    address1: "",
    country: "",
    state: "",
    city: "",
    postal_code: "",
    note: "",
    tinnitus_start_date: new Date(),
    tinnitus_type: "",
    ears: "",
    // q_value: 0

  },
  buttonText: "Add",
  heading: "Add New Patient",
};

export default patientForm;
