import React from "react";
// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "blikol-layouts/components/DashboardNavbar";
import { useSelector } from "react-redux";
import ArgonBox from "components/ArgonBox";
import useClinicService from "service-hooks/useClinicService";
import DataTable from "examples/Tables/DataTable";
import { useState, useEffect, useMemo } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { useNavigate } from "react-router-dom";
import ActionMenu from "blikol-layouts/components/ActionMenu";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Spinner from "../../../components/Spinner";
import Header from "blikol-layouts/components/Header"
import useExportCSVService from "service-hooks/useExportCSVService";
import { useParams } from "react-router-dom";
import Patient from "blikol-layouts/clinics/components/View/components/Patients";
import Credits from "blikol-layouts/clinics/components/View/components/Credits"
import Detail from "blikol-layouts/clinics/components/View/components/Detail";
import { amount } from "parsers/formatParser"
import ArgonTypography from "components/ArgonTypography";
import "../../style.css"
import PropTypes from "prop-types";

function View() {

    const [tabValue, setTabValue] = useState("detail")
    const { user, token } = useSelector((state) => state.auth)
    const userRole = user?.role
    const [clinicId, setClinicId] = useState(useParams().id);
    const [patientCount, setPatientCount] = useState()
    const clinicHookService = useClinicService()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [clinicData, setClinicData] = useState({})
    const [pageRefresh, setPageRefresh] = useState(false);
    function formatTabText(word) {
        if (typeof word !== 'string' || word.length === 0) {
            return word;
        }

        // Remove numbers, brackets, and other symbols, and then convert to lowercase
        const cleanedWord = word.replace(/[^a-zA-Z]/g, '').toLowerCase();

        return cleanedWord.charAt(0).toLowerCase() + cleanedWord.slice(1);
    }
    const handlePageRefreshChange = () => {
        setPageRefresh(!pageRefresh);
    };

    const handleTabChange = (selectedTab) => {
        selectedTab = formatTabText(selectedTab)
        setTabValue(selectedTab);
    };

    useEffect(() => {
        clinicHookService
            .clinic(clinicId)
            .then((result) => {
                if (result) {
                    setClinicData(result?.data)
                    setPatientCount(result.data.no_of_patients);
                    setLoading(false)
                } else {
                    toast.error("No Clinic with this id");
                    navigate(`/${userRole}/clinics`);
                }
            })
            .catch((error) => {
                // Handle the error (e.g., display an error message)
                console.error("Error fetching user data and attributes:", error);
            });
    }, [pageRefresh]);

    const CreditPriceComponent = ({ clinicData }) => {
        return (
            <ArgonTypography
                mb={3}
                mt={4}
                sx={{
                    color: 'var(--Label, #8392AB)',
                    fontSize: '20px',
                    fontStyle: 'normal',
                    fontWeight: 600,
                    lineHeight: 'normal',
                }}
            >
                Credit Price:
                <span className="credit-price-value">
                    {clinicData?.default_credit_price_active ? "Standard Price" : amount(clinicData?.credit_price)}
                </span>
            </ArgonTypography>
        );
        CreditPriceComponent.defaultProps = {
            clinicData: {}
        };

        CreditPriceComponent.propTypes = {
            clinicData: PropTypes.object,

        };
    };
    return (
        <DashboardLayout>
            <DashboardNavbar customRoutePath={"Clinics"} />
            {loading && <Spinner />}
            {!loading && (
                <ArgonBox>
                    <Grid container spacing={3} alignItems="center" mb={2}>
                        <Grid item xs={6} md={6} lg={3.5}>
                            <Header
                                onTabChange={handleTabChange}
                                numTabs={3}
                                tabNames={["Detail", `Patients (${patientCount})`, "Credits"]}
                            />
                        </Grid>
                    </Grid>
                    <Card p={1}>
                        <ArgonBox>
                            {tabValue === "patients" && (
                                <Patient />
                            )}
                            {tabValue === "credits" && (
                                <Credits />
                            )}
                            {tabValue === "detail" && (
                                <Detail
                                    clinicInfo={clinicData}
                                    htmlComponent={() => <CreditPriceComponent clinicData={clinicData} />}
                                    onPageRefreshChange={handlePageRefreshChange}

                                />
                            )}
                        </ArgonBox>
                    </Card>
                </ArgonBox>
            )}
        </DashboardLayout>
    );
}

export default View;
