import { useState, useEffect, useMemo } from "react";
import DataTable from "examples/Tables/DataTable";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useTransactionHistoryService from '../../../../../service-hooks/clinic/useTransactionHistoryService'
import DownloadIcon from "assets/images/icons/clinic/download.svg"
import ActionMenu from "blikol-layouts/components/ActionMenu";
import React from 'react'
import useExportCSVService from "../../../../../service-hooks/useExportCSVService";
import Spinner from "blikol-layouts/components/Spinner";
function CreditBonus() {
    const transactionServiceHook = useTransactionHistoryService()
    const { user, token } = useSelector((state) => state.auth)
    const [clinicId, setClinicId] = useState(user?.id)
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [data, setData] = useState();
    const [search, setSearch] = useState("");
    const [pageNo, setPageNo] = useState(1);
    const [totalEntries, setTotalEntries] = useState();
    const [totalPages, setTotalPages] = useState();
    const [dateRange, setDateRange] = useState()
    const [tabValueForService, setTabValueForService] = useState("bonus_credits")
    const exportCSVServiceHook = useExportCSVService()
    const [loading, setLoading] = useState(true)


    useEffect(() => {
        transactionServiceHook
            .credit_bonus(pageNo, entriesPerPage, clinicId)
            .then((result) => {
                if (result && result?.meta && result?.bonus_credits) {
                    setData(result);
                    setLoading(false)
                    setTotalEntries(result?.meta?.total_count);
                    setTotalPages(result?.meta?.total_pages);
                } else {
                    toast.error("Data is undefined or incomplete.");
                }
            })
            .catch((error) => {
                // Handle the error (e.g., display an error message)
                toast.error("Error fetching user data and attributes:", error);
            });

    }, [
        entriesPerPage,
        pageNo,
        search,
        dateRange
    ]
    );

    const handleExportCSV = () => {
        const pathname = `/clinic_apis/clinics/${clinicId}/${tabValueForService}_export`
        const filename = `clinic_${clinicId}_bonus_credits`
        exportCSVServiceHook.exportCSV(pathname, filename)
    };

    const bonusRowsData = data?.bonus_credits?.map((bonus, index) => ({
        ...bonus,
    })) ?? [];

    const tableDataBonus = {
        columns: [
            { Header: "date", accessor: "date" },
            { Header: "credits bonus", accessor: "credits" },
        ],
        rows: bonusRowsData,
    };

    return (
        <>
            {loading && <Spinner />}
            {!loading && (
                <DataTable
                    canExportCSV={{
                        name: "Export as CSV",
                        action: handleExportCSV
                    }}
                    dataTableName={`Credit Bonus (${totalEntries})`}
                    entriesPerPage={entriesPerPage}
                    totalPages={totalPages}
                    setEntriesPerPage={setEntriesPerPage}
                    pageNo={pageNo}
                    setPageNo={setPageNo}
                    totalEntries={totalEntries}
                    table={tableDataBonus}
                    lastFixed={false}

                />
            )}
        </>
    )
}

export default CreditBonus