// src/components/CardSetupForm.js
import React, { useState, useEffect } from "react";
import { useStripe, useElements, PaymentElement } from "@stripe/react-stripe-js";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
const PaymentSetupForm = ({ setOpen, setProcessing, heading, redirectionUrl, saveButtonText }) => {
  const stripe = useStripe();
  const elements = useElements();
  const handleConfirmSetup = async () => {
    if (!stripe || !elements) {
      return;
    }
    try {
      const result = await stripe.confirmSetup({
        elements,
        confirmParams: {
          return_url: redirectionUrl ? redirectionUrl : window.location.href,
        },
      });
      setProcessing(true);
      if (result.error) {
        console.log("error:", result.error);
        setProcessing(false);
        toast.error(result.error.message);
      } else {
        setOpen && setOpen(false);
        toast.success("Payment Method added successfully");
      }
    } catch (error) {
      toast.error("Invalid card details");
    }
  };

  return (
    <ArgonBox
      px={3}
      pt={3}
      sx={{
        width: "100%",
      }}
    >
      {heading && (
        <ArgonTypography
          sx={{
            color: "#172B4D",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: "600",
            lineHeight: "137%",
            letterSpacing: "-0.8px",
            marginBottom: "25px",
            textAlign: "center",
          }}
        >
          {heading}
        </ArgonTypography>
      )}
      <ArgonBox mb={2}>
        <ArgonTypography
          sx={{
            fontSize: "13px",
            marginBottom: "5px",
            fontWeight: "600",
          }}
        >
          Card Holder Name
        </ArgonTypography>
        <ArgonInput type="text" placeholder="Name" size="large" label="Card Holder Name" mb={3} />
      </ArgonBox>
      <PaymentElement />
      <ArgonBox
        mt={3}
        sx={{
          display: "flex",
          justifyContent: "end",
          justifyContent: setOpen ? "end" : "start",
        }}
      >
        {setOpen && (
          <ArgonButton
            size="large"
            color="info"
            variant="outlined"
            onClick={() => setOpen(false)}
            display="flex"
            sx={{
              padding: "5px 40px 5px 40px",
              marginRight: "15px",
            }}
          >
            Cancel
          </ArgonButton>
        )}
        <ArgonButton
          size="large"
          color="info"
          onClick={handleConfirmSetup}
          display="flex"
          sx={{
            padding: "5px 40px 5px 40px",
          }}
        >
          {saveButtonText}
        </ArgonButton>
      </ArgonBox>
    </ArgonBox>
  );
};
PaymentSetupForm.propTypes = {
  setOpen: PropTypes.func,
  setProcessing: PropTypes.func,
  redirectionUrl: PropTypes.string,
  heading: PropTypes.string,
  saveButtonText: PropTypes.string,
};
export default PaymentSetupForm;
