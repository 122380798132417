import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

function Header({ onTabChange }) {
    const [tabValue, setTabValue] = useState(0);

    const generateTabStyles = (index) => {
        const isSelected = tabValue === index;
        return {
            backgroundColor: isSelected ? "info.main" : "initial",
            color: isSelected ? "#fff !important" : "#555F61 !important",
            "&:hover": {
                backgroundColor: isSelected ? "info.main" : "initial",
            },
            "&.Mui-selected": {
                backgroundColor: isSelected ? "info.main" : "initial",
                color: isSelected ? "#fff !important" : "#555F61 !important",
            },
        };
    };

    const handleSetTabValue = (event, newValue) => {
        setTabValue(newValue);

        // Get the label of the selected tab and pass it to the parent component
        const selectedTabLabel = event.currentTarget.textContent;
        onTabChange(selectedTabLabel);
    };



    return (
        <Grid container spacing={3} alignItems="center" mb={2}>
            <Grid item xs={12} md={6} lg={4}>
                <AppBar position="static" >
                    <Tabs
                        value={tabValue}
                        onChange={handleSetTabValue}
                        indicatorColor="info"
                        TabIndicatorProps={{ style: { transitionDuration: '0ms' } }}
                    >
                        <Tab label="Active" sx={generateTabStyles(0)} />
                        <Tab label="Inactive" sx={generateTabStyles(1)} />
                        <Tab label="Pending" sx={generateTabStyles(2)} />
                    </Tabs>
                </AppBar>
            </Grid>
        </Grid >
    );
}

Header.propTypes = {
    onTabChange: PropTypes.func.isRequired,
};

export default Header;
