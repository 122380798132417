/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import useSettingService from "service-hooks/useSettingService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FormField from "layouts/pages/users/new-user/components/FormField";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import Spinner from "blikol-layouts/components/Spinner";
function CreditPrice() {
    const settingHookService = useSettingService();
    const [creditPrice, setCreditPrice] = useState(); // Initialize with null
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        settingHookService.settings().then((result) => {
            if (result && result.data && result.data.credit_price) {
                console.log("Settings:", result);
                setCreditPrice(result.data.credit_price);
                console.log(creditPrice)
                setLoading(false)
            } else {
                console.log("Unable to fetch settings");
            }
        });
    }, []);

    const validationSchema = Yup.object({
        credit_price: Yup.number()
            .positive("Credit Price should be greater than 0")
            .integer("Credit Price must be an integer")
            .required('Credit Price is required'),
    });

    const handleSubmit = (values) => {
        settingHookService
            .update({ credit_price: values.credit_price })
            .then(() => {
                toast.success(`Credit Price settings updated successfully.`);
            })
            .catch((error) => {
                toast.error(error);
            });
    };

    return (
        <>
            {loading && <Spinner />}
            {!loading && <Grid item xs={6}>
                <Card sx={{ overflow: "hidden" }}>
                    <ArgonBox p={3}>
                        <ArgonTypography variant="h5">Credit Price</ArgonTypography>
                    </ArgonBox>
                    <Formik
                        initialValues={{ credit_price: creditPrice }} // Initialize with an object
                        validationSchema={validationSchema}
                        onSubmit={handleSubmit}
                    >
                        {(props) => (
                            <Form>
                                <ArgonBox pb={3} px={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <FormField
                                                label="Price (€)"
                                                placeholder="e.g 23"
                                                name="credit_price"
                                                value={props.values.credit_price}
                                                onChange={props.handleChange}
                                                type="number"
                                                required={true}
                                                error={props.errors.credit_price && props.touched.credit_price}
                                            />
                                        </Grid>
                                        {console.log("first", props.values)}
                                    </Grid>
                                </ArgonBox>
                                <ArgonBox px={3} pt={1} pb={2}>
                                    <ArgonButton type="submit" color="info" disabled={Object.keys(props.errors).length !== 0}>
                                        Save
                                    </ArgonButton>
                                </ArgonBox>
                            </Form>
                        )}
                    </Formik>
                </Card>
            </Grid>}
        </>
    );
}

export default CreditPrice;
