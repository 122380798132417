import React from 'react'
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import PersonIcon from "assets/images/icons/patients/person-icon.svg"
import usePatientsService from "service-hooks/usePatientsService";
import Spinner from "../../../../../components/Spinner";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useParams } from "react-router-dom";
import ArgonButton from "components/ArgonButton";
import Rating from "../../components/Rating"
import { formatTimestamp } from 'parsers/formatParser';


function SessionHistory() {
    const navigate = useNavigate();
    const patientHookService = usePatientsService();
    const [patientData, setPatientData] = useState();
    const userRole = useSelector((state) => state.auth?.user?.role);
    const [patientId, setPatientId] = useState(useParams().id);

    useEffect(() => {
        patientHookService
            .patient(patientId)
            .then((result) => {
                if (result) {
                    const patient = result.data;
                    setPatientId(patient?.id);
                    setPatientData(
                        result.data
                    );
                } else {
                    toast.error("No Patient with this id");
                    navigate(`/${userRole}/patients`);
                }
            })
            .catch((error) => {
                console.error("Error fetching user data and attributes:", error);
            });
    }, []);

    const fieldNameStyle = {
        color: 'var(--Label, #8392AB)',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
    }

    const fieldValueStyle = {
        color: "var(--Text, #1A1D1F)",
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: '150%',
        marginTop: "6px"

    }

    const frequencyFormat = (value) => {
        return `${value} Hz`;
    };

    const durationFormat = (value) => {
        if (value === 1) {
            return `${value} min`;

        } else {
            return `${value} mins`;
        }

    };


    return (
        <Card>
            <ArgonBox sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}
                pt={5}
                px={4}
            >
                <ArgonTypography
                    sx={{
                        color: 'var(--Text, #1A1D1F)',
                        fontFamily: 'Open Sans',
                        fontSize: '20px',
                        fontStyle: 'normal',
                        fontWeight: 600,
                        lineHeight: '137%', /* 27.4px */
                        letterSpacing: '-0.8px',
                    }}
                >
                    Session History
                </ArgonTypography>

            </ArgonBox>
            <Grid item xs={12} sm={12} p={3}>
                {
                    patientData?.sessions.map((session, index) => (
                        <Card
                            key={index}
                            sx={{
                                padding: '30px',
                                gap: '10px',
                                borderRadius: '20px',
                                background: '#F4F2F2',
                                marginBottom: "25px",
                            }}
                        >
                            <ArgonTypography
                                sx={{
                                    color: '#172B4D',
                                    fontSize: '14px',
                                    fontStyle: 'normal',
                                    fontWeight: 700,
                                    lineHeight: '150%',

                                }}
                            >
                                {formatTimestamp(session.created_at)}
                            </ArgonTypography>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <ArgonBox
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <ArgonTypography sx={fieldNameStyle}>Session Duration</ArgonTypography>
                                        <ArgonTypography sx={fieldValueStyle}>{durationFormat(session?.duration)}</ArgonTypography>

                                    </ArgonBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <ArgonBox
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <ArgonTypography sx={fieldNameStyle}>Frequency</ArgonTypography>
                                        <ArgonTypography sx={fieldValueStyle}>{frequencyFormat(session?.frequency)}</ArgonTypography>

                                    </ArgonBox>
                                </Grid>
                                <Grid item xs={4}>
                                    <ArgonBox
                                        sx={{
                                            display: "flex",
                                            flexDirection: "column"
                                        }}
                                    >
                                        <ArgonTypography sx={fieldNameStyle}>Feedback</ArgonTypography>
                                        {/* <ArgonTypography sx={fieldValueStyle}>{session?.feedback}</ArgonTypography> */}
                                        <Rating points={session?.feedback} />
                                        {console.log(session?.feedback)}

                                    </ArgonBox>
                                </Grid>
                            </Grid>

                        </Card>
                    ))
                }

            </Grid>

        </Card>

    )
}

export default SessionHistory