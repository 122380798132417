import useBaseService from "./useBaseService";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const useTransactionHistoryService = () => {
    const axiosRequest = useBaseService().axiosRequest();
    const history = async (currentPage, itemsPerPage, selectedOption = "", clinicId) => {
        try {
            const response = await axiosRequest.get(`/administrator_apis/clinics/${clinicId}/credits_history?page=${currentPage}&items_per_page=${itemsPerPage}&date_range=${selectedOption}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const credit_bonus = async (currentPage, itemsPerPage, selectedOption = "", clinicId) => {
        try {
            const response = await axiosRequest.get(`/administrator_apis/clinics/${clinicId}/bonus_credits?page=${currentPage}&items_per_page=${itemsPerPage}&date_range=${selectedOption}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    const credits_used = async (currentPage, itemsPerPage, query = "", selectedOption = "", clinicId) => {
        try {
            const response = await axiosRequest.get(`/administrator_apis/clinics/${clinicId}/used_credits?page=${currentPage}&items_per_page=${itemsPerPage}&query=${query}&date_range=${selectedOption}`);
            return response.data;
        } catch (error) {
            toast.error(error.response.data.error);
            return false;
        }
    };

    return {
        history,
        credit_bonus,
        credits_used,
    };
};

export default useTransactionHistoryService;
